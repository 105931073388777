.userImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.subVehicle {
color: #CED4D8 !important;
}
.subVehicle .active{
    color: #495057 !important;
    }

.portpic {
    transform: rotate(89deg);
    width: 100%;
    display: inline-block;
    
}
.portpic img{
    max-width: 120px;
    animation: mymove 5s infinite;
}


@keyframes mymove {
  50% {transform: rotate(90deg);}
}

.potrt {
	color: #FFFFFF;
	opacity: 1;
	top: 50%;
    transform: translateY(-50%);
	position: absolute;
	text-align: center;
	display: inline-block;
	width: 100%;
}
#landscape{
    position: fixed;
    top: 0;
    right: 0;
}
.loader{
    background-color: #202629;
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
}
.loader-img {
    
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.loader-img img{
    width: 150px;
    animation-duration: 5s;
    animation-fill-mode: both;
    display: inline-block;
}

.fadeOutLeft { animation-name: fadeOutLeft }
@keyframes fadeOutLeft {
    from { opacity: 1 }
    to {
        opacity: 1;
        transform: translateX(-100%);
    }
}





.owl-theme .owl-dots .owl-dot span {
  background-color: #869791 !important;
  opacity: 0.33;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #D6D6D6 !important;
  opacity: 1;
}



/* #869791 */




.outer{
    position: fixed;
    width: 100%;
    top: 0%;
    left: 0%;
    background-color: #202629;
    z-index: 99999;
    height: 100%;
}

.inner {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}
.inner img{
    width: 200px;
    /* animation-duration: 5s;
    animation-fill-mode: both;
    display: inline-block; */
}

.loadertext{
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0%);
  width: 100%;
  text-align: center;
}

/* h1 {
    font-size: 4.3em;
    letter-spacing: -4px;
    font-weight: 700;
    color: white;
    text-align: center;
} */

.cssanimation, .cssanimation span {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.cssanimation span { display: inline-block }

.fadeOutLeft { animation-name: fadeOutLeft }
@keyframes fadeOutLeft {
    from { opacity: 1 }
    to {
        opacity: 0;
        transform: translateX(-100%);
    }
}
.highlightOption{
    background: #FFFFFF !important;
    color: #202629 !important;
}
/* .chip {
    background: #202629 !important;
    border-radius: 0px !important;
} */
.closeArw{display: flex; align-items: center; justify-content: center; border-radius: 100%; 
color: #F8B756; font-style: normal; margin-left: 5px; width: 15px;height: 15px;font-size: 9px;}

.closeArw img{
  width: 100%;
}
.custom-close{
    position: static !important;
    transform: translateX(25%) !important;
    pointer-events: visible !important;
}

.payment-container {
    width: 100vw;
    text-align: center;
    padding: 40px 0;
    /* background: #EBF0F5; */
  }
  .payment-card h1 {
      color: #88B04B;
      font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
      font-weight: 900;
      font-size: 40px;
      margin-bottom: 10px;
    }
    .payment-card p {
      color: #404F5E;
      font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
      font-size:20px;
      margin: 0;
    }
    .payment-card i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
  }
  .payment-card {
    background: #F8B756;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
  }
  
  
  .payment-container .teal{
    background-color: #ffc952 !important;
    color: #444444 !important;
  }
  .payment-container a{
    color: #47b8e0 !important;
  }
  .payment-container .message{
    text-align: left;
  }
  .payment-container .price1{
      font-size: 40px;
      font-weight: 200;
      display: block;
      text-align: center;
  }
  .payment-container .ui.message p {margin: 5px;}
  .icon_down_dir,.icon_cancel.closeIcon{
    display: none;
  }
  .multiSelectContainer li {
    color: #989898;
  }
  .multiSelectContainer li:hover {
    color: #FFFFFF;
  }

  .mobileSelect{display: none;}

  .modal.dashbordModal{
    pointer-events: none;
  }
 
/* .modalBackdrop.show {
  background-color: #CED4D8;
  } */
  .modalBackdrop {
    position: absolute;
    height: 200%;
    opacity: 1!important;
    max-width: calc(100% - 500px) !important;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0;
    top:0;
    background:#CED4D8 !important;
    z-index: 9;
    
  }
  .modalBackdrop.show { opacity: 1!important;}

  .editimage:first-child{
    border:10px solid #f4ba63;
    padding: 5px;
  }
  .bigCity{
    font-size: 7px !important;
  }
.smallCity{
  font-size: 6px !important;
  line-height: 8px !important;
}
  @media only screen and (max-width: 1600px) {
    .multiSelectContainer ul {
      max-height: 220px !important;
    }
    .modalBackdrop {
      max-width: calc(100% - 400px) !important;
    }
  }
  @media only screen and (max-width: 1500px) {
    .modalBackdrop {
      max-width: calc(100% - 360px) !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .deskSelect{display: none;}
    .mobileSelect{display: block;}
    .modalBackdrop {
      max-width: calc(100%) !important;
    }
    .bigCity{
      font-size: 11px !important;
    }
  .smallCity{
    font-size: 9px !important;
    line-height: 9px !important;
  }
  .grid-view-filter  .bigCity{
      font-size: 7px !important;
    }
    .grid-view-filter  .smallCity{
      font-size: 5px !important;
      line-height: 5px !important;
    }
    /* .loadertext{
      bottom: 20%;
    } */
  }
  @media only screen and (max-width: 575px) {
    .payment-container{margin-top:78px ;padding-top: 0;}

    .modal-backdrop.fade {
      opacity: 1 !important;
      background-color: rgb(206 212 216)!important;
  }
   
  }


