@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700;800;900&display=swap');







body {
  padding: 0px;
  margin: 0px;
  list-style: none;
  font: normal 15px/23px 'Inter';
      /* font-family: 'Inter', sans-serif; */
  color: #000;
}

p {
  padding: 0px;
  margin: 0px;
}

a {
  padding: 0px;
  margin: 0px;
  outline: none;
  text-decoration: none !important;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

label {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

ul li {

  padding: 0px;
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
}

input[type="text"]::-webkit-placeholder {
  opacity: 1;
}

input[type="text"]::-moz-placeholder {
  opacity: 1;
}

input[type="password"]::-webkit-placeholder {
  opacity: 1;
}

input[type="password"]::-moz-placeholder {
  opacity: 1;
}

input[type="search"]::-webkit-placeholder {
  opacity: 1;
}

input[type="search"]::-moz-placeholder {
  opacity: 1;
}

input[type="email"]::-webkit-placeholder {
  opacity: 1;
}

input[type="email"]::-moz-placeholder {
  opacity: 1;
}


.paypal-buttons {
  width: 500px !important;
}
input[type="tel"]::-webkit-placeholder {
  opacity: 1;
}

input[type="tel"]::-moz-placeholder {
  opacity: 1;
}

textarea::-moz-placeholder {
  opacity: 1;
}

textarea::-webkit-placeholder {
  opacity: 1;
}

a:hover {
  outline: none !important;
  text-decoration: none;
}

a:active {
  outline: none !important;
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

a,
button,
input[type="submit"] {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  border-radius: 0;
}

input[type="submit"] {
  cursor: pointer;
  border: 0px;
  outline: none;
  background: none;
  border-radius: 0;
}
input{
  border-radius: 0;
}

img {
  /* width:100%; */
  max-width: 100%;
  height: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 13px;
}

p:last-child {
  margin-bottom: 0px;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0px;
}

figure {
  margin: 0px;
}
#other_details figure{
  margin-bottom: 10px;
}
/* .filtrdts{
  margin-top: 15px;
} */

 .grayHeart img, .userSmall img{
  vertical-align: inherit;
 
 }

/* inter-100 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/inter-v12-latin-100.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-100.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-100.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-100.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-100.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/inter-v12-latin-200.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-200.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-200.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-200.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/inter-v12-latin-300.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-300.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v12-latin-regular.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-regular.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/inter-v12-latin-500.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-500.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/inter-v12-latin-600.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-600.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/inter-v12-latin-700.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-700.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/inter-v12-latin-800.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-800.svg#Inter') format('svg');
  /* Legacy iOS */
}

/* inter-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/inter-v12-latin-900.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/inter-v12-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/inter-v12-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/inter-v12-latin-900.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/inter-v12-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/inter-v12-latin-900.svg#Inter') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?4gs07t');
  src: url('./fonts/icomoon.eot?4gs07t#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?4gs07t') format('truetype'),
    url('./fonts/icomoon.woff?4gs07t') format('woff'),
    url('./fonts/icomoon.svg?4gs07t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Frame1:before {
  content: "\e900";
}
.icon-Frame-4:before {
  content: "\e901";
  /* color: #f8b756; */
}
.icon-Frame-3:before {
  content: "\e902";
}
.icon-Frame-2:before {
  content: "\e903";
}
.icon-Frame-1:before {
  content: "\e904";
}
.icon-Frame:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90d";
}
.icon-pinterest:before {
  content: "\e907";
}
.icon-linkedIn:before {
  content: "\e90e";
}
.icon-instagram1:before {
  content: "\ea92";
}




.mobileTphder.fixed,
.navbar-brand {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

.mobileTphder.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -moz-box-shadow: 0 0 8px -5px #333;
  -webkit-box-shadow: 0 0 8px -5px #333;
  box-shadow: 0 0 8px -5px #333;
}


@-webkit-keyframes animationFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes animationFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.mobileTphder.fixed {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  margin: 0;
  z-index: 1002;
  -webkit-animation-name: animationFade;
  -o-animation-name: animationFade;
  animation-name: animationFade;
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -moz-box-shadow: 0 0 8px -5px #333;
  -webkit-box-shadow: 0 0 8px -5px #333;
  box-shadow: 0 0 8px -5px #333;
}



/*
font-family: 'Inter';
*/

body {
  background: #CED4D8;
  padding: 0 0 150px;
}

.desktopAds {
  width: 100%;
}

.desktopAdspnl {
  width: 100%;
  background: #202629;
  max-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.desktopAdspnl img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktopAdspnl h1 {
  color: #575757;
  font-weight: 400;
  font-size: 64px;
  line-height: 70px;
}

.searchSec {
  width: 100%;
  margin: 29px 0 0;
}

.serchpnl {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.searchlft {
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #F8B756;
  height: 45px;
}
.saveseller{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 150px;
  background: rgb(255, 255, 255) !important;
}
.saveseller img{
width: 90px;
margin-bottom: 10px;
}

.searchlft input[type="text"],
.searchlft input[type="search"] {
  
  width: 100%;
  height: 100%;
  padding: 0 55px 0 18px;
  border: 0;
  outline: none;
  color: #575757;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  background: none;
}

.searchlft input[type="text"]::placeholder,
.searchlft input[type="search"]::placeholder {
  color: #575757;
}

.searchlft input[type="submit"] {
  width: 26px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 15px;
  padding: 0;
  border: 0;
  outline: none;
  background: url(./images/27_search.svg) no-repeat center center;
}

.searchfltr {
  width: 100%;
  margin-left: 29px;
  max-width: 251px;
}

.searchfltr button {
  width: 100%;
  height: 45px;
  border: 0px;
  outline: none;
  background: #202629 url(./images/26_sort.svg) no-repeat left 8px top 10px;
  text-align: center;
  color: #E3E3E3;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding: 0;
  -webkit-appearance: none;
  background-size: 26px;
}

.productSec {
  padding: 2px 0 0;
  width: 100%;
}

.productbx {
  width: 100%;
  margin: 26px 0 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
  cursor: pointer;
  position: relative;
}

.productSec .row {
  margin: 0 -13.5px;
}

.productSec .row>div {
  padding: 0 13.5px;
}

.productbxtop {
  width: 100%;
  display: flex;
}

.productbxtopimg {
  width: 100%;
  position: relative;
  height: 105px;
  background: #333333;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productbxtopimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rentBtn{
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f8b756;
  color: #000;
    font-size: 10px;
    line-height: 10px;
    padding: 4px 4px;
    margin: 0;
}
.newBtn{
  top:0;
  right: 0;
  bottom: inherit;
  left: inherit;
}
.productbxtopimg h3 {
  color: #989898;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}



.userSmall {
  bottom: 4px;
  position: absolute;
  right: 3.5px;
  /* background: rgba(0,0,0,0.5); */
  width: 19px;
  height: 19px;
  /* padding: 2px; */
  line-height: 16px;
   /* filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 1.0)); */
}
.userSmall img{
  object-fit: inherit;
}

.grayHeart {
  left: 4px;
  position: absolute;
  top: 4px;
  /* background: rgba(0,0,0,0.5); */
  width: 20px;
  height: 20px;
  /* padding: 2px; */
  line-height: 16px; 
  /* filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 1.0)); */
}
/* .userSmall::before{
  width: 29px;
    height: 29px;
  position: absolute;
    content: "";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.65);
} */
/* .userSmall img {
  width: 150%;
  max-width: 150%;
} */



.productbxtoprtlst {
  width: 100%;
  max-width: 67px;
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
}

.productbxtoprtlst ul {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-right: 3px;
}

.productbxtoprtlst li {
	color: #989898;
	font-weight: 700;
	font-size: 7px;
	line-height: 12px;
	text-transform: uppercase;
	border-bottom: 1px solid #E3E3E3;
	padding: 3.5px 0 3.5px 5px;
	position: relative;
	display: flex;
	justify-content: space-between;
  align-items: center;
  text-align: right;
}
.productbxtoprtlst li span img{
  max-width: 10px;
}

.productbxtoprtlst li span{
  line-height: 0;
}

/* .productbxtoprtlst li:nth-child(4) {
  font-size: 8px;
}
.productbxtoprtlst li:nth-child(5) {
  font-size: 8px;
} */
.productbxbtm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 0 0 0 9px;
  position: relative;
}

.productbxbtm h3 {
  color: #333333;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  position: relative;
  width: 100%;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* .productbxbtm h3::after{
  position: absolute;
  right: 0;
  top:-5px;
  background: linear-gradient(91.75deg, rgba(255, 255, 255, 0) 1.82%, #FFFFFF 80.78%);
  content: '';
  width: 40px;
  height: 20px;
} */


.productSec.list-view-filter .productbxbtm h3::after{
  /* top: 0; */
  width: 46px;
  height: 27px;
}

.productbxbtm strong {
  text-align: center;
  width: 100px;
  color: #333333;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  position: relative;
}
.productbxbtm strong span {
	font-size: 7px;
	color: #989898;
	position: absolute;
	top: -10px;
	right: 5px;
	line-height: 8.5px;
  
}

.productbxbtm::before {
  position: absolute;
  content: "";
  bottom: 0;
  right: 67px;
  width: 1px;
  top: 50%;
    height: 80%;
    transform: translateY(-50%);
  border-right: 1px solid #E3E3E3;
  z-index: 2;
}
.productbxtoprtlst li em{
  font-style:normal ;
  max-width: 90%;
  text-align: right;
  display: inline-block;
}
.leftPart {
  background: #202629;
  max-width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.leftpartbtm {
  width: 100%;
  padding: 0 0 10px;
}

.leftpartbtm ul {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.leftpartbtm li {
  padding: 10px 0;
  border-top:0.5px solid #575757;
  width: 100%;
  text-align: center;
}

.leftpartbtm li a {
  color: #575757;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  text-transform: uppercase;
  display: inline-block;
}
.leftpartbtm li img {
width: 24px;
}
/* .leftpartbtm li:last-child {
  border: 0;
} */

.leftpartbtm li a:hover {
  color: #FFFFFF;
}

.leftpartTop {
  width: 100%;
}

.lftprtLgo {
  width: 100%;
  min-height: 140px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #575757;
}

.lftprtLgo img{
  width: 200px;
}
.lftprtLgo a{
  width: 100%;
}

.lftuser {
  width: 100%;
  /* border-top: 1px solid #575757; */
  border-bottom: 1px solid #575757;
  display: flex;
  padding: 45px 0;
  justify-content: flex-start;
  padding-left: 45px !important;
}

.media {
  display: flex;
}

.media-object {
  flex: 1;
  cursor: pointer;
}

.lftuser .media {
  align-items: center;
}

.lftuser .media>figure {
  margin-right: 16px;
  width: 40px;
}
.filterRghtBtn li img {
  width: 25px;
}

.lftuser .media h4 {
  color: #CED4D8;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 3px;
}

.lftuser .media strong {
  color: #575757;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}


.leftwhy .media {
  align-items: center;
}

.leftwhy .media>figure {
  margin-right: 16px;
}

.leftwhy .media h4 {
  color: #CED4D8;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 3px;
}

.leftwhy .media strong {
  color: #CED4D8;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.leftwhy {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-top: 1px solid #575757;
  border-bottom: 1px solid #575757;
  display: flex;
  padding: 32px 0;
  justify-content: center;
  width: 100%;
}

.rentTxt {
  position: absolute;
  top: 0;
  right: 0;
}

.newTxt {
  position: absolute;
  bottom: 0;
  left: 0;
}

.productbxtopimg>em {
  color: #333333;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  background: #F8B756;
  display: inline-block;
  font-style: normal;
  padding: 2px 6px;
}

.filterRght {
  padding: 0 0 88px;
  background: #202629;
  max-width: 250px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  height: 100vh;
  width: 100%;
}

.filterRghtTop {
  border-bottom: 1px solid #575757;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding-bottom: 10px; */
}

.filterRghtTop .media {
  align-items: center;
  padding: 30px 0;
}

.filterRghtTop .media h3 {
  color: #CED4D8;
  font-weight: 700;
  line-height: 33px;
  font-size: 27px;
}

.filterRghtTop .media>figure {
  margin: 5px 15px 0 0;
}

.filterRghtBtn {
  position: absolute;
  bottom: 22px;
  left: 0;
  width: 100%;
}

.filterRghtBtn ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterRghtBtn li {
  margin-right: 20px;
}

.filterRghtBtn li:last-child {
  margin: 0;
}

.filterRghtBtn li a {
  display: inline-flex;
  color: #333333;
  text-transform: uppercase;
  background: #F8B756;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  width: auto;
  height: 43px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.filterRghtBtn li:last-child a {
  background: none; padding: 0px;
}

.filterRghtBtn li a:hover {
  opacity: 0.75;
}

.filterProductLnk li button.active {
  background: #202629 !important;
  color: #CED4D8 !important;
}


.listflterCtgry .form-group {
  display: block;
}

.listflterCtgry .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.listflterCtgry .form-group label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  color: #E3E3E3;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 9px 15px 8px;
  text-align: center;
}

.listflterCtgry .form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #51575A;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  width: 13.6px;
  height: 13.6px;
  border-radius: 100%;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* .listflterCtgry .form-group input:checked+label:before {
  border-color: #F8B756;
  background: #F8B756;
} */

.listflterCtgry .form-group input.active+label:before {
  border-color: #F8B756;
  background: #F8B756;
}

.filterRghtbtm {
  width: 100%;
}

.filterProductLnk {
  width: 100%;
}

.filterProductLnk ul {
  border: 0;
  border-radius: 0;
}

.filterProductLnk li {
  width: 25%;
}

.filterProductLnk li button {
  background: #CED4D8 !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121 !important;
  width: 100%;
}

.filterProductLst {
  width: 100%;
}

.filterProductLstIner {
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  /* position: absolute; */
  top: 0;
  left: 0;
  padding: 0 !important;
  pointer-events: none;
}

.filterProductLstIner>div {
  pointer-events: visible;
}

.filterProductLstTop {
  width: 100%;
}

.filterProductLstTop li {
  width: 50%;
  border-bottom: 1px solid #575757;
  position: relative;
}

.filterProductLstTop ul {
  border: 0;
  border-radius: 0;
}

.filterProductLstTop li button {
  border: 0 !important;
  border-radius: 0 !important;
  background: none !important;
  color: #E3E3E3 !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 0 9px 40px;
  display: inline-block;
  width: 100%;
  text-align: left;
  position: relative;
}

.filterProductLstTop li button::before {
  position: absolute;
  content: "";
  width: 13.6px;
  height: 13.6px;
  border-radius: 100%;
  border: 2px solid #51575A;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.filterProductLstTop li button.active::before {
  background: #F8B756;
  border-color: #F8B756;
}

.filterProductLstTop li:nth-child(even) button {
  text-align: right;
  padding-left: 0;
  padding-right: 40px;
}

.filterProductLstTop li:nth-child(even) button::before {
  left: auto;
  right: 10px;
}

.filterProductLstTop li::before {
  position: absolute;
  content: "";
  right: 0;
  width: 1px;
  border-right: 1px solid #989898;
  height: 28px;
  top: 50%;
  transform: translateY(-50%);
}

.filterProductLstTop li:nth-child(even)::before {
  content: none;
}

.filterProductLstBtm {
  width: 100%;
}

.listflterCtgry {
  width: 100%;
}

.listflterCtgry ul {
  width: 100%;
  display: inline-block;
}

.listflterCtgry li {
  border-bottom: 1px solid #575757;
}

.filterProductLstIner .mCSB_inside>.mCSB_container {
  margin: 0;
}

.filterProductLstIner .mCSB_scrollTools {
  opacity: 0 !important;
  pointer-events: none;
}


.mobileAds,
.mobileTphder,
.mobilehder,
.botomBar,
.closeMnuTwo,
.gridBtn {
  display: none;
}

.userModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  outline: none;
}

.lftuser {
  position: relative;
}


.dashbordModal .modal-dialog {
  width: 100%;
  max-width: calc(100% - 500px);
  height: 100%;
  margin: 0 auto;
  transform: inherit !important;
}

.dashbordModal .modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 100%;
  background: #CED4D8;
}

.dashbordModal .modal-header {
  padding: 0;
  border: 0;
}

.btnClose {
  position: absolute;
  border: 0;
  border-radius: 0;
  outline: none;
  background: none;
  top: 27px;
  right: 27px;
  z-index: 9;
}

.dashbordModal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #CED4D8;
  flex-wrap: wrap;
}

.popbtomtxt {
	position: absolute;
	bottom: 30px;
	left: 0;
	width: 100%;
	text-align: center;
	max-width: 410px;
	right: 0;
	margin: 0 auto;
}
.popbtomtxt p{
  color: #959b9e;
  font-size: 24px;
  cursor: pointer;
}
.popbtomtxt p small{
  font-size: 20px;
}
.paypalbtomtxt p{
  font-size: 11px;
  font-style: italic;
  color: #7b8388;
  line-height: 16px;
}

.popbtomtxt img{
  max-width: 35px;
}
.paypalbtomtxt{
	width: 100%;
	text-align: center;
	max-width: 500px;
	margin: 0 auto;
}
.paypalbtomdetails{
  padding: 10px 0;
  position: relative;
}
.paypalbtomdetails::before{
  content: "";
  max-width: 300px;
  width: 100%;
  height: 2px;
  background-color: #7b8388;
  position: absolute;
  left:0;
  right: 0;
  top:0;
  margin: 0 auto;
}
.paypalbtomdetails p{
margin: 0;
font-size: 12px;
font-style: normal;
} 
.usermodal {
  max-width: 410px;
  width: 100%;
}

.usermodalbtm {
  width: 100%;
  margin: 100px 0 0;
  text-align: center;
}

.usermodalbtm>em {
  color: #989898;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  border-top: 1px solid #000000;
  padding: 9px 0 9px;
  display: inline-block;
}

.usermodalbtm figure {
  margin: 11px 0 0;
}

.registerBtn,
.loginBtn input[type="submit"] {
  display: inline-block;
  background: #333333;
  line-height: 35px;
  width: 233px;
  text-align: center;
  color: #989898;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}
.selerDelrTopbtm li>button img {
  width: 75px;
}

.usermodalbtm .registerBtn {
  margin: 11px 0 0;
}

.oldPic{
  position: relative;
}
.oldPic .deletePic{
  position: absolute;
  top:0px;
  right: 0px;
  width: 25px;
  height: 25px;
  /* border-radius: 50%; */
  background-color: #F8B756;
  text-align: center;
  cursor: pointer;
}
.oldPic .deletePic img{
  height: auto;
  width: 15px;
  object-fit: inherit;
}
.oldPic .hompic{
  background-color: #f8b756;
  left: 0;
  width: 30px;
  height: 30px;
}
.oldPic .hompic img{width: 30px;}

.registerBtn:hover,
.loginBtn input[type="submit"]:hover {
  background: #000000;
  color: #989898;
}

.usermodaltop {
  width: 100%;
}

.frmData {
  margin: 30px 0 0;
  position: relative;
}

.frmData input[type="text"],
.frmData input[type="password"],.frmData select, .frmData input[type="tel"] {
  width: 100%;
  height: 50px;
  color: #989898;
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  border: 0;
  outline: none;
  text-align: center;
  padding: 0 50px;
  text-align: -webkit-center;
  text-align-last: center;
}

.frmData input[type="text"]:focus,
.frmData input[type="password"]:focus,.frmData select:focus, .frmData input[type="tel"]:focus{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.frmData input[type="text"]::placeholder,
.frmData input[type="password"]::placeholder {
  opacity: 1;
  color: #989898;
  text-align: center;
}
.frmData textarea {
	width: 100%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	height: 50px;
	color: #989898;
	font-weight: 400;
	font-size: 27px;
	border: 0;
	outline: none;
	text-align: center;
	padding: 12px 20px;
  min-height: 300px;
}

.frmData .lstNameIn{
  height: auto;
  min-height: 105px;  
  font-size: 27px;
  line-height: 30px;
}
.frmData .lstNameIn::placeholder{
 font-size: 27px;
 line-height: 30px;
}

.frmData textarea::placeholder,
.frmData textarea::placeholder {
  opacity: 1;
  color: #989898;
  text-align: center;
}

.userLoked {
  text-align: center;
}

.userLoked img{
  max-width: 75px;
}

.frmData i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  width: 35px;
}

.loginBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loginBtn input[type="submit"] {
  margin: 30px 0 0;
}

.loginBtn>em {
  font-style: normal;
  margin: 18px 0 0;
  color: #989898;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
}

.loginBtn>em a {
  color: #989898;
}

.loginBtn>em a:hover {
  color: #000000;
}

.btnClose img:last-child {
  display: none;
}

.blankpnl {
  text-align: center;
}

.blankpnl h4 {
  color: #202629;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
}

.blankModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  outline: none;
}

.blankpnl figure img {
  width: 256px;
}

.usrpic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.usrpic img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.frtwobtn{
  display: none;
}
.mbilfltrnon img {
  width: 28px;
}

.customRadio { position: relative; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0 0 10px; }
.customRadio input[type="checkbox"] { position: absolute; overflow: hidden; clip: rect(0 0 0 0); display: none; }
.customRadio input[type="checkbox"] + label { position: relative; padding: 0 0 0 40px; cursor: pointer; }
.customRadio input[type="checkbox"] + label:before { content: ''; background: transparent; 
border: 2px solid #000; height: 25px; width: 25px; border-radius: 50%; position: absolute; top: 3px; left: 0; }
.customRadio input[type="checkbox"] + label:after { content: ''; background: #333333; 
width: 15px; height: 15px; border-radius: 50%; position: absolute; top: 8px; left: 5.5px; opacity: 0; -webkit-transform: scale(2); transform: scale(2); -webkit-transition: transform 0.3s linear, opacity 0.3s linear; transition: transform 0.3s linear, opacity 0.3s linear; }
.customRadio input[type="checkbox"]:checked + label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }

.customRadio input[type="radio"] { position: absolute; overflow: hidden; clip: rect(0 0 0 0); display: none; }
.customRadio input[type="radio"] + label { position: relative; padding: 0 0 0 40px; cursor: pointer; display: flex; align-items: center; }
.customRadio input[type="radio"] + label:before { content: ''; background: transparent; 
border: 2px solid #000; height: 25px; width: 25px; border-radius: 50%; position: absolute; top: auto; left: 0; }
/* .customRadio input[type="radio"] + label:after { content: ''; background: #333333; 
width: 15px; height: 15px; border-radius: 50%; position: absolute; top: 7.5px; left: 4.5px; opacity: 0; -webkit-transform: scale(2); transform: scale(2); -webkit-transition: transform 0.3s linear, opacity 0.3s linear; transition: transform 0.3s linear, opacity 0.3s linear; } */
/* .customRadio input[type="radio"]:checked + label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1); } */
.customRadio label.checked:after {content: ''; background: #333333; 
  width: 15px; height: 15px; border-radius: 50%; position: absolute; top: auto; left: 5px; -webkit-transition: transform 0.3s linear, opacity 0.3s linear; transition: transform 0.3s linear, opacity 0.3s linear; opacity: 1; -webkit-transform: scale(1); transform: scale(1); }

.customRadio label{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 33px;
color: #202629;
}


.seller-type-pop{
  width: 80%;
  margin: 0 auto;
  min-height: 350px;
}
.seller-type-pop .row{
  width: 100%;
  justify-content: center;
}
.seller-type-pop .row > div{
  text-align: center;
  position: relative;
}
.seller-type-pop .row > div:last-child::before{
  content: '';
  border-left: 1px solid rgba(32, 38, 41, 0.33);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 300px;
}
.ovrfHide .seller-type-pop .row > div:last-child::before,.ovrfHide .fulebx .row > div::before {
  display: none;
}
.seller-type-pop .customRadio input[type="radio"] + label:after { content: ''; background: #333333; 
width: 15px; height: 15px; border-radius: 50%; position: absolute; top: 7.5px; left: 4.5px; opacity: 0; -webkit-transform: scale(2); transform: scale(2); -webkit-transition: transform 0.3s linear, opacity 0.3s linear; transition: transform 0.3s linear, opacity 0.3s linear; }
.seller-type-pop .customRadio input[type="radio"].active + label:after { opacity: 1; 
  /* -webkit-transform: scale(1); 
  transform: scale(1);  */
}
.seller-type-pop .customRadio label{
  text-align: center;
}
.seller-type-pop .customRadio input[type="radio"] + label::after {
  top: auto;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.seller-type-pop .customRadio input[type="radio"] + label::before {
  top: auto;
  left: 50%;
  bottom: -40px;
  transform: translateX(-50%);
}
.seller-type-pop .customRadio input[type="radio"] + label {
  padding: 0;
}
.elricon{
  max-width: 125px;
  height: 125px;
  margin: 0 auto;
  position: relative;
}
.elricon img{
  max-width: 100% !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
  /* opacity: 0.33; */
}
.stear-icon{
  max-width: 125px;
  height: 125px;
  margin: 0 auto;
  position: relative;
}
.stear-icon img{
  max-width: 100% !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
  /* opacity: 0.33; */
}
.twordobx{
  width: 100%;
  text-align: center;
}
.twordobx .customRadio {
  display: inherit;
}
.twordobx p{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 27px;
color: #202629;
margin-top: 15px;
}
.okbtn{
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.oksbmit {
  width: 150px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8B756;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-family: 'Inter';
  font-weight: 600;
  font-size: 27px;
  color: #333333;
  text-transform: uppercase;
  margin: 0 auto;
}

.selrtypoup{
  width: 100%;
  display: block;
}
.selrtypoup img{
  max-width: 120px;
}
.stear-icon{
  text-align: center;
}
.stear-icon img{
  max-width: 100%;
}
.chosefrm {
  margin: 30px 0 0;
  position: relative;
  width: 100%;
}
.mblMrgn{
  cursor: pointer;
}
.chosefrm i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  z-index: 1;
}
.chosefrm .drpArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
}
.chosemk{
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 50px;
  color: #989898;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  border: 0;
  outline: none;
  text-align: center;
  /* padding: 0 50px; */
  appearance: none;
  background: #fff;
  cursor: pointer;
}
.chosemk option{
  text-align: center;
}
.pad50{
  padding: 0 50px;
}

.chosemk2{
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 50px !important;
  color: #989898;
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  border: 0;
  outline: none;
  text-align: center;
  padding: 0 20px;
  appearance: none;
  cursor: pointer;
}
.seletfrm{
  max-width: 320px;
  margin: 0 auto;
  /* cursor: pointer; */
}
.seletfrm.disabled{
  opacity: 0.33;
}
.price-range-popup{
  max-width: 572px;
  margin: 0 auto;
}
.pricicon{
  text-align: center;
}
.pricicon p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #202629;
  margin: 20px 0 30px;
}
.priceinput{
  width: 100%;
  display: flex;
  align-items: center;
}
.middlearw{
  min-width: 100px;
  text-align: center;
  margin: 30px 0 0;
}
.middlearw img{
  max-width: 60px;
}


.location-poup{
  max-width: 1030px;
  margin: 0 auto;
}
.mapopbx{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;
}
.mapic{
  max-width: 452px;
  width: 100%;
}
.mapic svg path{
  fill:#202629;
  opacity:0.33;
}
.mapic svg .active{
  fill:#202629;
  opacity:1;
}
.mapic img{
  max-width: 100%;
}
.midlor{
min-width: 169px;
text-align: center;
margin: 30px 0 0;
}
.midlor p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #202629;
  opacity: 0.45;
  text-transform: uppercase;
}
.mapopbx .chosefrm{
  max-width: 409px;
}
.otherdetl-popup{
  width: 100%;
}
.other-fill-box {
  margin-bottom: 20px;
}

.otherdetl-popup .allopfiltr{
  margin: 30px 0 30px;
}
.otherdetl-popup img{
  max-width: 85px;
}

.modalRegister {position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;visibility: hidden;transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 9999; overflow-x: hidden;
  overflow-y: auto;outline: 0;}
  .modalRegister .modal-content, .buyerModalOpen .modal-content { position: absolute; top: 50%;  left: 50%; transform: translate(-50%, -50%); background-color: #ced4d8;
  height: 100%;width: 100%; max-width: calc(100% - 500px); height: 100%;}
  .modalRegister .close-button {float: right;width: 1.5rem;font-size: 1.2em;line-height: 1;padding: 0 .2em .15em;text-align: center;
  cursor: pointer;border-radius: 0.25rem;background-color: var(--clr-neutral);color: var(--clr-dark);transition: color 0.12s ease-in-out;
  }
  .modalRegister .close-button:hover {color: var(--clr-main);}
  .show-modal {opacity: 1;visibility: visible;transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;}
  
  /*__________*/
  
  .closeButton, .closeButtonTwo{position: absolute; top: 27px; right: 27px; z-index: 9; cursor: pointer;}
  .closeButton img:last-child, .closeButtonTwo img:last-child {display: none;}
  .modalRegisterpnl{width: 100%; display: flex; align-items: center; justify-content: center; padding: 20px; flex: 1 1 auto;
  background-color: #ced4d8; }
  
  .selerDelrBx{width: 100%;}
  .selerDelrTop{text-align: center; width: 100%;}
  .selerDelrTopbtm{width: 100%; display: flex; justify-content: center; align-items: center;}
  .selerDelrTopbtm ul{display: inline-flex; align-items: center;}
  .selerDelrTopbtm li{padding: 0 83px; margin: 94px 0 0;}
  .selerDelrTopbtm li > button{background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); width: 347px; height: 235px;
  display: flex; align-items: center; justify-content: center; flex-direction: column; color: #202629; font-weight: 600;
  font-size: 27px; line-height: 33px; outline: none; border: 0px;}
  .selerDelrTopbtm li > button em{font-style: normal; display: block; margin: 10px 0 0;}
  
  /*__________*/
  .buyerModalOpen {position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;visibility: hidden;transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 99999; overflow-x: hidden;
  overflow-y: auto;outline: 0;}
  .buyerModalOpen .modal-content { position: absolute; top: 50%;  left: 50%; transform: translate(-50%, -50%); background-color: #ced4d8;
  height: 100%;width: 100%; max-width: calc(100% - 500px); height: 100%;}
  .buyerModalOpen .close-button {float: right;width: 1.5rem;font-size: 1.2em;line-height: 1;padding: 0 .2em .15em;text-align: center;
  cursor: pointer;border-radius: 0.25rem;background-color: var(--clr-neutral);color: var(--clr-dark);transition: color 0.12s ease-in-out;
  }
  .show-modal2 {opacity: 1;visibility: visible;transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;}
  /*__________*/
  
  .frmData select{ cursor: pointer; background: #FFFFFF; -webkit-appearance: none; resize: none;}
  .registermodal .loginBtn input[type="submit"]{width: 100%; height: 53px;
  padding: 0;margin: 50px 0 0; display: flex;align-items: center;justify-content: center;}
  .registermodal .usermodalbtm{margin: 0}
  .usermodalbtm img{max-width: 75px;}
  .registermodal .registerBtn{margin: 35px 0 0; width: 100%;
  height: 53px;display: flex;align-items: center;justify-content: center;}
  
  .rghttootip{position: absolute; left: 100%; bottom: 0; display: flex;align-items: flex-end;width: 100%;margin-left: 25px;}
  .registermodal .frmData{position: relative;}
  .rghttootipMsg{max-width: 268px; width: 100%; background: #FFFFFF; padding: 15px 10px 20px; text-align: center;
  margin-left: 20px; opacity: 0; visibility: hidden; transition: all 0.3s ease-in-out;}
  .rghttootipMsg h4{color: #989898; font-weight: 400; font-size: 17px; line-height: 21px; margin: 0 0 20px;}
  .rghttootipMsg p{color: #989898; font-weight: 400; font-size: 15px; line-height: 20px;}
  .tooltipIcon{border: 0px; outline: none; background: none; padding: 0;}
  .tooltipIcon img{width: 40px;}
  .showTooltip .rghttootipMsg{opacity: 1; visibility: visible;}
  
  body.workingFrm .modal.dashbordModal{pointer-events: none; visibility: hidden;}
  
  .fogotPassModal {position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;visibility: hidden;transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 99999; overflow-x: hidden;
    overflow-y: auto;outline: 0;}
    .fogotPassModal .modal-content { position: absolute; top: 50%;  left: 50%; transform: translate(-50%, -50%); background-color: #ced4d8;
    height: 100%;width: 100%; max-width: calc(100% - 500px); height: 100%;}
    .show-forgot {opacity: 1;visibility: visible;transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;}
    /*__________*/
    .closeButtonfogot{position: absolute; top: 27px; right: 27px; z-index: 9; cursor: pointer;}
    .closeButtonfogot img:last-child {display: none;}
    
    .fogotPassModal .usermodal{margin: 0 auto;}
    .fogotPassModal .registermodal .loginBtn input[type="submit"]{margin: 30px 0 0;}
    .fogotPassModal .usermodaltop{margin: 75px 0 0;}
    .fogotPassModal .usermodaltop:first-child{margin: 0;}
    .selerDelrTop img {
      width: 100px;
  }
    .carDelarRght .filterRghtTop .media{flex-direction: column;}
    .carDelarRght .filterRghtTop .media > figure{margin: 0 0 7px; width: 48px;}
    .carDelarRght .filterRghtTop .media h3{font-size: 15px; line-height: 20px;}
    
    .carDelarRghtLst{width: 100%;}
    .carDelarRghtLst ul{display: inline-block; width: 100%;}
    .carDelarRghtLst li{display: flex; align-items: center; border-bottom: 1px solid #575757; padding: 30px 20px 30px 51px;cursor: pointer;}
    .carDelarRghtLst li span{color: #CED4D8; font-weight: 600; line-height: 18px; font-size: 15px; text-transform: uppercase;}
    .carDelarRghtLst li i{margin-right: 10px;}
    .carDelarRghtBtmMsg{bottom: 30px; left: 23px; text-align: center; position: absolute; width: 100%; padding: 10px;}
    .carDelarRghtBtmMsg span{color: #454545; font-weight: 600; font-size: 21px; line-height: 25px;}
    .carDelarRghtLst i img{max-width: 31px;width: 100%;}
    
    .listingHed{width: 100%; margin: 35px 0 0;}
    .listingHedinr{display: flex; justify-content: space-between; 
    align-items: center; }
    .listingHedinr > div{background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); width: 100%; flex: 1;
    display: flex;}
    .listingHedinr > a{display: flex; align-items: center; justify-content: center; background: #F8B756;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); margin-right: 33px; width: 68px; height: 69px;}
    .listingHedinr h1{color: #202629; font-weight: 700; font-size: 23px; line-height: 28px; padding: 18px; 
      /* text-transform: uppercase;  */
    margin: 0; width: 100%; display: flex; align-items: center; justify-content: space-between;}
    .listingHedinr strong{color: #202629; font-weight: 700; font-size: 27px; line-height: 33px; background: #F8B756; width: 100%;
    max-width: 177px; display: flex; align-items: center; justify-content: center; text-align: center; }
    
    .listingDtils{width: 100%; margin: 33px 0 0;}
    .listingDtilslft{width: 100%;}
    .detailedDescrip{width: 100%; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); background: #FFFFFF; min-height: 168px; padding: 10px;
    margin: 33px 0 0; position: relative;}
    .detailedDescrip h4{color: #989898; font-weight: 700; font-size: 15px; line-height: 18px; text-align: center;}
    .listingDtilsRt{width: 100%;}
    .listingDtilsRtbtm{width: 100%;}
    .listingDtilsRtbtm img{width: 52px;}
    .listingDtilsRtbtm ul{display: flex; width: 100%;}
    .listingDtilsRtbtm li{width: 50%; min-height: 168px; display: flex; flex-direction: column; align-items: center; justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); background: #FFFFFF; }
    .listingDtilsRtbtm h5{color: #202629; font-weight: 700; font-size: 15px; line-height: 18px; text-transform: uppercase; margin: 6px 0 0;}
    .listingDtilsRtbtm li:last-child{background: #F8B756;}

    .actok{
      background: #F8B756;
      padding: 15px 15px;
      color: #000;
      font-size: 14px;
      width: 150px;
    }
    
    .listingDtilslftSldr{width: 100%; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);position: relative;  overflow: hidden;}
    .listingDtilslftSldr > div{width: 100%;position: relative; max-height: 422px; overflow: hidden; text-align: center;
      background: #202629; }
    .listingDtilslftSldr .imgCount{position: absolute;bottom: 10px;right: 10px;width: auto;z-index: 2;    overflow: initial;background: transparent;}
    .listingDtilslftSldr .imgCount::before{position: absolute;
      content: "";
      top: -6px;
      bottom: -5px;
      left: -7px;
      right: -7px;
      border-radius: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.65); }
    .listingDtilslftSldr .imgCount span{color:#FFFFFF;font-size: 16px; font-weight: 600;}
    .listingDtilslftSldr > div a{width: 100%;position: relative; height: 422px; overflow: hidden;}
    .listingDtilslftSldr a img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .detailsrentbtn{
      z-index: 9;
      bottom: inherit;
      left: 0px;
      top: 0px;
      padding: 10px 12px;
      font-size: 14px;
  }
    .listingDtilslftSldr .whishCount{
      position: absolute;
      left:0;
      bottom: 10px;
      right: 0;
      max-width: 30px;
      margin: 0 auto;
      z-index: 1; background: transparent;
    }
    .listingDtilslftSldr .whishCount img{
      opacity: 1;
      max-width: 100%;
    }
    .listingDtilslftSldr .whishCount i{
      font-style: normal;
    }
    .listingDtilslftSldr .whishCount span{
      position: absolute;
      left: 0;
      top:55%;
      right: 0;
      margin: auto;
      text-align: center;
      transform: translateY(-55%);
      font-size: 12px;
      font-weight: 600;
    }
    .carDetilsImge{width: 100%; background: #333333; max-height: 402px; display: flex; align-items: center; justify-content: center;}
    .carDetilsImge h4{color: #989898; font-weight: 700; line-height: 33px; font-size: 27px;}
    .listingDtilslftSldr .owl-nav{display: flex !important;position: absolute;top: 50%;transform: translateY(-50%);width: 100%;
    align-items: center;justify-content: space-between; padding: 0 20px; pointer-events: none;}
    .listingDtilslftSldr .owl-nav button span{display: none;}
    /* .listingDtilslftSldr .owl-nav button::before{color: #989898; font-weight: 700; font-size: 27px; line-height: 0px; position: absolute;}
    .listingDtilslftSldr .owl-prev::before{content: "<"; }
    .listingDtilslftSldr .owl-next::before{content: ">"; } */
    .listingDtilslftSldr .owl-nav button{ width: 22px; height: 22px; display: flex; align-items: center; justify-content: center;
    pointer-events: visible;}
    .listingDtilslftSldr .owl-prev{left: 15px;}
    .listingDtilslftSldr .owl-next{right: 15px;}
    .listingDtilslftSldr .owl-dots{position: absolute; bottom: 20px; left: 50%; transform: translateX(-50%);  counter-reset: my-sec-counter;}
    /* .listingDtilslftSldr .owl-dots button::before{counter-increment: my-sec-counter; content: "" counter(my-sec-counter);
    opacity: 0; color: #202629; font-size: 21px; line-height: 25px; font-weight: 700; } */
    /* .listingDtilslftSldr .owl-dots button span{display: none;} */
    /* .listingDtilslftSldr .owl-dots button:last-child::before{opacity: 1; } */
    /* .listingDtilslftSldr .owl-dots button{display: flex; align-items: center; width: 47px; height: 47px; justify-content: center; 
    position: relative; opacity: 0; visibility: hidden;} */
    /* .listingDtilslftSldr .owl-dots button::after{position: absolute; content: ""; background: url(./images/Love.png) no-repeat center center;
    width: 100%; height: 100%; z-index: -2;} */
    /* .listingDtilslftSldr .owl-dots button:last-child{opacity: 1; visibility: visible;} */
    
    .listingHedinr h1 > em{font-style: normal;}
    
    .selerListing{width: 100%; margin: 33px 0 0;}
    .selerListinghed{width: 100%; text-align: center; background: #FFFFFF; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 11px 0px;margin-top: 30px; }
    .selerListinghed h3{color: #989898; font-weight: 700; font-size: 15px; line-height: 18px;}
    
    .selerListingSldr{padding: 0 37px;}
    .selerListingSldr .owl-dots{display: none !important;}
    .selerListingSldr .owl-nav{display: flex !important;}
    .selerListingSldr .owl-nav button span{display: none;}
    .selerListingSldr .owl-nav button::before{color: #989898; font-weight: 700; font-size: 27px; line-height: 33px; position: absolute;}
    .selerListingSldr .owl-prev::before{content: "<"; }
    .selerListingSldr .owl-next::before{content: ">"; }
    .selerListingSldr .owl-nav button{ width: 22px; height: 22px; display: flex; align-items: center; justify-content: center;
    pointer-events: visible; position: absolute; top: 50%; transform: translateY(-50%); }
    .selerListingSldr .owl-prev{left: -37px;}
    .selerListingSldr .owl-next{right: -37px;}
    
    .listingLst{width: 100%; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); background: #FFFFFF; min-height: 422px; margin: 0 0 33px;
    display: flex; flex-direction: column;}
    .listingLstBtm{width: 100%; align-self: flex-end;}
    
    .listingLstTop, .listingLstBtm{width: 100%; }
    .listingLstTop li, .listingLstBtm li{color: #989898; font-size: 14px; line-height: 16px; font-weight: 700;
     display: flex; align-items: center; position: relative; padding: 3px 14px;}
     .detailsAttri ul{
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #989898;
     }
     .detailsAttri em{
      width: 100% !important;
     }
     .detailsAttri li{
      width: 50%;
      border-bottom: 1px solid #989898;
     }
     .detailsAttri li:last-child,.detailsAttri li:nth-last-child(2){
      border-bottom: 0;
     }
     .detailsAttri li::before,.detailsAttri ul::before{
      border-bottom: 0 !important;
     }
    .listingLstTop li::before, .listingLstBtm li::before{position: absolute; content: ""; bottom: 0; left: 14px; right: 14px; border-bottom: 1px solid #989898; height: 1px;}
    .listingLstTop li em, .listingLstBtm li em{font-style: normal; width: 50%; position: relative; font-weight: 600; font-size: 12px;}
    .listingLstTop li b{font-weight: 700; color: #202629;}
     .listingLstBtm li:last-child::before{content: none;}
    .listingLstBtm li b{font-weight: 700;}
    .ShowBtn{display: inline-flex; background: #F8B756;padding-left: 10px;
       /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    width: 100%; color: #202629; font-weight: 700; font-size: 13px; line-height: 18px; height: 27px; align-items: center; 
     position: relative;}
    .listingLstBtm li:last-child b{width: 50%;}
    .ShowBtn i{position: absolute; right: 15px;}
    .ShowBtn:hover{color: #202629; opacity: 0.85;}
    /* .listingLstBtm li:last-child{padding: 0 0 0 14px;} */
    .listingHedinr strong{
      position: relative;
    }
    .listingHedinr strong em {
      font-style: normal;
      position: absolute;
      right: 6px;
      bottom: 6px;
      font-size: 17px;
      opacity: 0.33;
      line-height: 16px;
    }
    /* .listingLstTop li:nth-last-child(1) em::before,
    .listingLstTop li:nth-last-child(2) em::before,
    .listingLstTop li:nth-last-child(3) em::before{content: "."; position: absolute; right: 0; color: #202629; font-weight: 700; 
    line-height: 18px; font-size: 15px;}
    .listingLstTop li:nth-last-child(1)::before,
    .listingLstTop li:nth-last-child(2)::before,
    .listingLstTop li:nth-last-child(3)::before{content: none;} */
    
    .carDelarRghtLst li.hverShow{position: relative; display: flex; align-items: center; }
    .carDelarRghtLst li em{color: #202629; font-weight: 700;font-size: 21px;line-height: 25px; display: none; font-style: normal; }
    .carDelarRghtLst li.hverShow:hover{background: #F8B756;}
    .carDelarRghtLst li.hverShow:hover i{display: none;}
    .carDelarRghtLst li.hverShow:hover span{display: none;}
    .carDelarRghtLst li.hverShow:hover em{display: inline-block;}
    .carDelarRghtLst li {
      min-height: 92px;
    }
    .delermobile{display: none;}
  
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: transparent !important;
  }

  .listingLst{position: relative;}

  .showModal{position: absolute; top: 0; left: 0; width: 100%; height: 100%; transform: inherit; opacity: 0;
  visibility: hidden; background: #FFFFFF; z-index: 99;}
  .showModal .modal-dialog{margin: 0px; transform: inherit !important; height: 100%; width: 100%; max-width: inherit;}
  .showModal .modal-content{transform: inherit; border: 0px; border-radius: 0px; height: 100%;}
  .showModalClose{position: absolute; top: 10px; right: 10px; z-index: 9; padding: 0px; border: 0px; outline: none;
  background: none;}
  .showModalLst{padding: 70px 0px 0;
    width: 100%;
    overflow: auto;}
  .showModalLst .listingLstTop li:nth-last-child(1)::before,
  .showModalLst .listingLstTop li:nth-last-child(2)::before,
  .showModalLst .listingLstTop li:nth-last-child(3)::before{content: "";}
  .showModalLst .listingLstTop li{color: #202629; padding-top: 6px; padding-bottom: 6px;}
  .showModalLst .listingLstTop li em::before{content: none;}
  
  .hideOverly .modal-backdrop{opacity: 0; visibility: hidden;}
  /* .listingDtilslftSldr .owl-dots{pointer-events: none;} */
  /* .listingDtilslftSldr .owl-dots button:last-child{pointer-events: visible;} */
  .miniOverly{position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 99; opacity: 0; visibility: hidden;}
  .hideOverly .miniOverly{opacity: 1; visibility: visible;}
  .hideOverly .showModal{opacity: 1; visibility: visible;}
  .hedrnxtbck{
    z-index: 9;
    position: relative;
  }
  .backButon {
    background: none;
    border: 0px;
    outline: none;
    position: relative;
    left: 0;
    width: 40px;
    height: 40px;
  }
  .backButon img{
    width: 100%;
    height: 100%;
  }
  .delerbotomBar{display: none;}
  
  .CAR_DEALER .listingHedinr strong{font-size: 21px; line-height: 25px;}
  .CAR_DEALER .listingHedinr h1 > em{width: 36px;}
  .listingDtilsRt .detailedDescrip{margin: 0; min-height: 400px;}
  /* .CAR_DEALERSldrSec .listingDtilslftSldr .owl-dots{display: none !important;} */
  .listingDtilsRt .detailedDescrip h4 em{position: relative; font-style: normal; padding: 0 0 7px;}
  .listingDtilsRt .detailedDescrip h4 em::before{position: absolute; content: ""; left: 50%; transform: translateX(-50%); bottom: 0;
  width: 80%; height: 1px; border-top: 1px solid #989898; }
  .CAR_DEALERSldrSec .carDetilsImge img{
    height: 402px;
    object-fit: cover;
  }
  .listingDtilsRt .selerListinghed, .ACTIVE_LISTINGS{margin: 33px 0 0;}
  .ACTIVE_LISTINGS{width: 100%; position: relative; background: #202629; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); text-align: center; padding: 11px 30px; display: flex;
  align-items: center; justify-content: center;cursor: pointer;}
  .ACTIVE_LISTINGS h3{color: #FFFFFF; font-weight: 700; font-size: 15px; line-height: 18px; margin: 0;}
  .ACTIVE_LISTINGS i{position: absolute; right: 9px;
    width: 20px;top:7px}
  
  .activeListing .selerListinghed{margin: 33px 0 0;cursor: pointer;}
  .CAR_DEALERSldrads{display: none;}
  .ctrgyList{display: none;}
  .FINISHED_LISTINGS_product{width: 100%;}
  
  .leftwhy .media h4{margin: 0 !important;}
  .leftwhy .media h4{font-size: 27px; line-height: 30px;}
  .leftpartbtm li{padding: 0;}
  .leftpartbtm li a{display: flex; align-items: center; padding: 9px 10px 9px 60px; text-align: left;}
  .leftpartbtm li a i{margin-right: 10px;}
  .leftwhy .media > figure{margin-top: 3px;width: 45px;}


  .leftpnlactiv {
    width: 100%;
    padding: 15px 35px 15px;
    border-bottom: 1px solid #575757;
  }

  .buypnl{
    border-bottom: none !important;
  }
  .leftpnlactiv ul {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .leftpnlactiv li {
    padding: 10px 0;
    width: 100%;
  }
  .leftpnlactiv li a {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    display: inline-block;
  }
  .leftpnlactiv li a span{
    color:#575757;
  }
  .leftpnlactiv li a i img{max-width: 12px;margin-right: 8px;}
  .leftpnlactiv h6{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #F8B756;
  }
  .adwhy{
    /* position: relative;
    top: inherit;
    transform: inherit; */
    display: none;
  }
  .lftprtLgo {
    min-height: 106px;
  }
  .lftusrpdd {
    padding: 22px 0;
  }
  .iputprice {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 129px;
    color: #989898;
    font-weight: 400;
    font-size: 27px;
    line-height: 33px;
    border: 0;
    outline: none;
    text-align: center;
    padding: 0 50px;
    appearance: none;
  }

  /* Custom Checkbox */
.customCheckbox { position: relative; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin: 0; }
.customCheckbox input[type="checkbox"] { position: absolute; overflow: hidden; clip: rect(0 0 0 0); display: none; }
.customCheckbox input[type="checkbox"] + label { position: relative; padding: 0 0 0 30px; margin-bottom: 0; cursor: pointer; color: #202629;font-weight: 700;font-family: 'Inter'; opacity: 0.33; }
.customCheckbox input[type="checkbox"] + label:before { content: ''; background: transparent; border: 2px solid rgba(32, 38, 41, 1); border-radius: 0; height: 20px; width: 20px; position: absolute; top: 15px; left: 0; }
.customCheckbox input[type="checkbox"] + label:after { content: ''; border-style: solid; border-width: 0 0 2px 2px; border-color: transparent transparent #333333 #333333; width: 12px; height: 7px; position: absolute; top: 20px; left: 5px; opacity: 0; -webkit-transform: scale(1) rotate(-45deg); transform: scale(1) rotate(-45deg); -webkit-transition: transform 0.3s linear, opacity 0.3s linear; transition: transform 0.3s linear, opacity 0.3s linear; }
.customCheckbox input[type="checkbox"]:checked + label:after { opacity: 1; -webkit-transform: scale(1) rotate(-45deg); transform: scale(1) rotate(-45deg); border-color: #fff; }
.customCheckbox input[type="checkbox"]:checked  + label:before{background: #202629;}
.customCheckbox input[type="checkbox"]:checked ~ label {opacity: 1;}

.chkbox-optin{
  max-width: 290px;
  margin:30px auto 0;
}

.chkbox-optin .customRadio{
  display: inline-block;
  width: 49%;
}
.twochkline .customRadio:nth-child(2){
  text-align: right;
}
.twochkline .customRadio:nth-child(2) input[type="radio"] + label {
	padding: 0 30px 0 0;
}
.twochkline .customRadio:nth-child(2) input[type="radio"] + label::before {
	right: 0;
	left: auto;
}
.twochkline .customRadio:nth-child(2) input[type="radio"] + label::after {
	right: 5px;
  left: auto;
}
.twochkline > .customRadio input[type="radio"] + label::after {
	content: '';
	border-style: solid;
	border-width: 0 0 2px 2px;
	border-color: transparent transparent #fff #fff;
	width: 12px;
	height: 7px;
	position: absolute;
  top: 10px;
  left: 7px;
	opacity: 0;
	-webkit-transform: scale(1) rotate(-45deg);
	transform: scale(1) rotate(-45deg);
	-webkit-transition: transform 0.3s linear, opacity 0.3s linear;
	transition: transform 0.3s linear, opacity 0.3s linear;
  background: none;
  border-radius: inherit;
}
.twochkline > .customRadio input[type="radio"]:checked + label::after {
	opacity: 1;
	-webkit-transform: scale(1) rotate(-45deg);
	transform: scale(1) rotate(-45deg);
	color: #6d6d6d;
}
.twochkline > .customRadio input[type="radio"] + label::before {
	height: 25px;
	width: 25px;
	border-radius: 3px;
  border: 2px solid #202629;
}
.twochkline > .customRadio input[type="radio"]:checked + label::before {
	background: #202629;
}

.twochkline .customRadio label {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 33px;
	color: #202629;
  opacity: 0.33;
}
.twochkline .customRadio [type="radio"]:checked + label {
	opacity: 1;
}
.twochkline{
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.33);
}
.twochkline:last-child{
  border: none;
}
.hedrnxtbck {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 15px 30px;
}
.hedrnxtbck img{
  width: 50px;
  opacity: 0.9;
}
.hedrnxtbck a:last-child{
  transform: rotate(180deg);
}
/* .yrfuel{
  padding: 0 30px;
} */
.yrfuel p{
font-family: 'Inter';
font-weight: 700;
font-size: 24px;
line-height: 30px;
text-align: center;
color: #202629;
}
.yrfuel p span{
  display: block;
  opacity: 0.33;
  font-size: 18px;
  }
.yrfuel .stear-icon {
	max-width: 95px;
	height: 95px;
}
/* .yrfuel .stear-icon img{
	opacity: 0.33;
} */
.yrfuel .chosefrm {
	margin: 20px 0 0;
}
.fulebx .row > div::before {
	content: '';
	border-left: 1px solid rgba(32, 38, 41, 0.33);
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	height: 256px !important;
}
.fulebx .row > div:first-child::before {
	display: none;
}
.allrdiopop{
  padding: 0 30px;
}
.allrdiopop > div figure{
  margin-bottom: 10px;
}
#pormotlist .allrdiopop > div figure{margin-bottom: 25px;}
#pormotlist .allrdiopop > div figure img{
  /* opacity: 0.33; */
  max-width: 150px;
}
#pormotlist  .automatic{margin-top: 40px;}
#pormotlist .allrdiopop > div figure {
  margin-bottom: 0px;
}
.frmdtabx{
  max-width: 410px;
  margin: 0 auto;
}
.assignbtn {
  font-family: 'Inter';
	margin: 15px 0 0;
	width: 100%;
	height: 53px;
	display: flex;
	align-items: center;
	justify-content: center;
  line-height: 35px;
  color: #202629;
  font-weight: 700;
  font-size: 23px;
  text-transform: uppercase;
  background: #F8B756;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#pormotlist .assignbtn{margin-top: 30px;}
/* .paypalBtn{
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
} */
.automatic{
  margin-top: 80px;
}
.automatic .customCheckbox input[type="checkbox"] + label {
	font-weight: 500;
  opacity: 1;
  font-size: 20px;
  text-transform: capitalize;
}
.automatic .customCheckbox input[type="checkbox"] + label span{
	font-size: 15px;
  opacity: 0.33;
  display: block;
}

.uplodbtn{
  padding: 10px 20px 0px;
  max-width: 272px;
  width: 100%;
  margin: 40px auto 0;
  position: relative;
}
.upldlnk {
	position: absolute;
	z-index: 5;
	left: 0;
	top: 0;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 23px;
	text-align: center;
	color: #202629;
	background: #F8B756;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 100%;
	padding: 10px 20px;
	text-transform: uppercase;
  cursor: pointer;
}
#original {
	opacity: 0;
	position: relative;
	z-index: 100;
	width: 100%;
	cursor: pointer;
	height: 50px;
}
.fstcalink{
  display: flex;
justify-content: center;
align-items: center;
width: 100%;
}
.carselct {
	width: 25%;
	text-align: center;
	max-width: 202px;
  position: relative;
  padding: 0 12px;
  cursor: pointer;
}
/* .carselct::before{
  position: absolute;
  width: 1px;
  height: 60px;
  background: rgba(0, 0, 0, 0.33);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
} */
.carselct:last-child::before{
  display: none;
}
.whtxt {
	text-align: center;
	margin-top: 100px;
	position: absolute;
	bottom: 15px;
	left: 0;
	width: 100%;
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 33px;
color: #202629;
}

.allcredit-sec {
	width: 100%;
	display: flex;
	gap: 50px;
	max-width: 800px;
	margin:100px auto;
}
.credtbx{
  width: 100%;
}
.creditinner{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding:15px 15px 5px;
}
.credtbx a{
  background: #F8B756;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  display: block;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #202629;
  margin-top: 0px;
}

.creditblnc {
	max-width: 400px;
	margin: 0 auto;
  text-align: center;
}
.creditblnc h5{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 28px;
text-align: center;
color: #202629;
}
.creditblnc h6{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: rgba(32, 38, 41, 0.54);
}
.creditblnc p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: rgba(32, 38, 41, 0.54);
}
.creditblnc p strong{
  margin: 0 2px;
}
.creditblnc hr{
  max-width: 78%;
  margin: 10px auto;
}
.creditinner h4{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  text-align: center;
  color: #333333;
  margin: 0;
}
.creditinner span{
  font-family: 'Inter';
  font-size: 24px;
  color: #333333;
}
.creditinner hr{
  max-width: 100px;
  margin: 10px auto;
}
.backButon {
	transform: rotate(180deg);
}
.delerbotomBar ul li i img{
  max-width: 32px;
  margin: 6px 0;
}
.sortpnl figure img{
width: 74px;
}
.carDelarRghtBtmMsg ul {
	display: inline-block;
	width: 100%;
}
.carDelarRghtBtmMsg li {
	padding: 10px 10px 10px 0 !important;
	min-height: auto;
}


.carDelarRghtBtmMsg li span {
	color: #CED4D8;
	font-weight: 600;
  line-height: 1px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
}
/* 91062023 */
.oglasitext{color: #989898; font-weight: 700; font-size: 17px;  text-align: center; margin-top: 20px;}
.viditext{color: #989898; font-weight: 700; font-size: 19px; padding: 20px 0px; text-align: center;}


.searchWrapper input{margin: 0 !important; padding: 0px !important; pointer-events: none; font-size: 16px; line-height: 20px;
width: auto;}
.searchWrapper{border: 0 !important; border-radius: 0 !important; min-height: 50px !important; padding: 7px 25px !important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); color: #989898; cursor: pointer; display: flex; align-items: center; 
background: #fff; flex-wrap: wrap;}
.reg .searchWrapper{padding: 0 !important;box-shadow: none;}
.chosefrm .drpArrow {z-index: 9;}

.autoSalon .customRadio input[type="radio"] + label{flex-direction: column;}
.elricon{width: 100%;}

.cros_icon{position: absolute;top: 0;right: 0;background: #f8b756;
border: 0;outline: none;height: 100%;width: 50px;padding: 13px;display: flex;align-items: center;justify-content: center;}
.cros_icon img{filter: invert(37%) sepia(10%) saturate(290%) hue-rotate(357deg) brightness(95%) contrast(95%);}

 .chosemk:focus{color: #202629;}
.iputprice{color: #202629;}
.iputprice::placeholder{color: #989898;}
.customRegis .registermodal .loginBtn input[type="submit"]{width: 65%;}

.numberCount{display: flex; justify-content: center; }
.numberCount span{color: #202629;font-size: 25px;line-height: 30px;padding: 5px 11px;}

.soldMainModal .soldMainModal {padding: 0;}
.btnCloseds{position: absolute; top: 10px; right: 10px; color: #fff; border-radius: 8px; width: 30px;
display: flex; align-items: center; justify-content: center; font-size: 14px; border: 0px; padding: 0px !important; outline: none;z-index: 99;}
.soldMainModal .modal-body{padding: 0;}
.soldModal{text-align: center; padding: 35px 50px 20px;}

.soldmodalCon{text-align: center;}
.soldmodalCon h5{font-size: 14px; line-height: 24px;}
.soldmodalBtns{margin: 24px 0 0;}
.soldmodalBtns li{padding: 0 8px;}
.soldmodalBtns button{padding: 0; border: 0px; outline: none; padding: 6px 15px; background: #000000; color: #fff; font-size: 13px;}
.soldmodalBtns button:hover{opacity: 0.65;}
.soldmodalBtns ul{display: flex; align-items: center; justify-content: center;}

.fixedborderbox{ position: absolute; top: 0; left: 0;z-index: 99999999999999;}
.fixedborderbox .maincardpic{height: 100px;}  
.soldTag{
  text-align: center;
  background-color:#e22402;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
  min-width: 150px
}

.moblhid{
  display: block !important;
}
.dskHide{
  display: none !important;
}

.upascred{
  text-align: center;
  margin: 15px;
  opacity: 0.33;
}

.paypalmodal{
  padding: 20px 250px;
}

/* __________Responsive__________ */
@media only screen and (max-width: 1850px) {
   .listflterCtgry .form-group label {
    padding: 7px 15px 6px;
}
}
@media only screen and (max-width: 1800px) {
  .listflterCtgry .form-group label {
    font-size: 12px;
    line-height: 16px;
    padding: 7px 15px 6px;
}
  .filterRghtBtn {
    bottom: 10px;
}
.filterProductLstTop li button {
	font-size: 13px;
	line-height: 16px;
	padding: 8px 0 8px 40px;
}
.filterProductLnk li button {
	height: 45px;
}
.filterRghtTop {
	min-height: 106px;
}
.filterRghtTop .media {
	padding: 14px 0;
}
.filterRghtTop .media h3 {
	line-height: 28px;
	font-size: 22px;
}
}

@media screen and (min-width: 1200px) {

  .container {
    max-width: 1118px;
  }



}


@media (min-width: 1700px) and (max-width: 1799px) {

  .dashbordModal .modal-dialog {
    max-width: calc(100% - 500px);
  }

  .modalRegister .modal-content, .buyerModalOpen .modal-content{max-width: calc(100% - 500px);}
.selerDelrTopbtm li > button{padding: 0;}
.selerDelrTop img{width: 90px;}
.selerDelrTopbtm li { padding: 0 60px; margin: 70px 0 0;}
.selerDelrTopbtm li > button {width: 305px; height: 200px;font-size: 22px;line-height: 28px;}
  
/* __NEW__ */
.registermodal .usermodalbtm {margin: 50px 0 0;}
.registermodal .loginBtn input[type="submit"] {margin: 35px 0 0;}
.registermodal .frmData {margin: 25px 0 0;}


/*_____ForgotPass_____*/
.fogotPassModal .modal-content{max-width: calc(100% - 500px);}

.filterProductLstTop li button{font-weight: 600;}
.lftprtLgo img{width: 200px;}


}

.chosemk {text-overflow: ellipsis;}


@media only screen and (max-width: 1600px) {
  .paypalmodal{
    padding: 20px 200px;
  }
  .yrfuel p {
    font-size: 23px;
  }
  .leftPart,
  .filterRght {
    max-width: 200px;
  }

  body {
    padding: 0 0 90px;
  }

  .lftprtLgo img {
    width: 150px;
  }

  .lftprtLgo {
    min-height: 110px;
  }

  .lftuser {
    padding: 30px 0;
  }

  .leftwhy {
    padding: 20px 0;
  }

  .leftwhy .media>figure {
    margin-right: 15px;
    width: 45px;
  }

  .leftwhy .media h4 {
    font-size: 16px;
    line-height: 23px;
    margin: 0 0 2px;
  }

  .leftwhy .media strong {
    font-size: 20px;
    line-height: 25px;
  }

  .lftuser .media h4 {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 2px;
  }

  .lftuser .media strong {
    font-size: 15px;
    line-height: 18px;
  }

  .lftuser .media>figure {
    margin-right: 15px;
    width: 40px;
  }

  .leftpartbtm li a {
    font-size: 15px;
    line-height: 20px;
  }

  .filterProductLstTop li button {
    padding: 9px 0 9px 31px;
  }

  .filterProductLstTop li:nth-child(2n) button {
    padding-right: 31px;
  }

  .filterRghtBtn li {
    margin-right: 10px;
  }

  .filterRghtBtn li a {
    font-size: 12px;
    line-height: 15px;
    height: 26px; 
    padding: 0 10px;
  }
  .filterRghtBtn li img{width: 20px;}

  .filterProductLnk li button {
    font-size: 12px;
  }

  .filterRghtTop .media h3 {
    line-height: 25px;
    font-size: 20px;
  }

  .filterRghtTop .media>figure {
    margin: 0px 0 0 0;
    width: 30px;
  }

  /* .filterRghtTop {
    min-height: 70px;
  } */

  .filterProductLstIner {
    padding: 144px 0 0;
    height: calc(100% - 82px);
  }
  /* .listflterCtgry .form-group label {
    font-size: 12px;
    line-height: 15px;
  } */
  .filterProductLstTop li button, .listflterCtgry .form-group label {
    font-size: 11px;
    line-height: 14px;
  }
  .filterProductLnk li button {
    height: 40px;
}

/* .filterRghtTop .media {
  padding: 23px 0;
} */



  .dashbordModal .modal-dialog {
    max-width: calc(100% - 400px);
  }

  .modalRegister .modal-content, .buyerModalOpen .modal-content{max-width: calc(100% - 400px);}
  .selerDelrTopbtm li > button{padding: 0;}
  .selerDelrTop img{width: 80px;}
  .selerDelrTopbtm li { padding: 0 40px; margin: 60px 0 0;}
  .selerDelrTopbtm li > button {width: 260px; height: 175px;font-size: 20px;line-height: 25px;}
  .selerDelrTopbtm li > button img{width: 75px;}
  
  .rghttootipMsg {max-width: 230px; padding: 12px;}
  .rghttootipMsg h4 {font-size: 16px; line-height: 20px; margin: 0 0 10px;}
  .rghttootipMsg p {font-size: 14px; line-height: 20px;}
  

  /* __NEW__ */
.registermodal .usermodalbtm {margin: 40px 0 0;}
.registermodal .loginBtn input[type="submit"] {margin: 25px 0 0;}
.registermodal .frmData {margin: 20px 0 0;}
.registermodal .registerBtn {margin: 25px 0 0;}


/*_____ForgotPass_____*/
.fogotPassModal .modal-content{max-width: calc(100% - 400px);}

.filterRghtBtn{bottom: 5px;}


/* ___29_5_23 */
.carDelarRghtLst li {padding: 20px 15px 20px 20px; min-height: 72px;}
.carDelarRghtLst li span {line-height: 17px;font-size: 11px;}
.carDelarRghtLst li i {margin-right: 8px; width: 30px;}
.carDelarRghtLst li em {font-size: 18px; line-height: 24px;}
.carDelarRghtBtmMsg span {font-size: 17px; line-height: 24px;}
.carDelarRghtBtmMsg { bottom: 20px; padding: 8px;left: 10px;}

.leftwhy .media strong img{width: 55px;}
.leftpartbtm li a {
	padding: 6px 8px 6px 45px;
	font-size: 13px;
	line-height: 18px;
}
.leftpartbtm li a i {margin-right: 15px;}

.lftprtLgo {
  min-height: 85px;
}
.filterRghtTop {
  min-height: 85px;
}
.lftusrpdd {
  padding: 12px 0;
}
.leftpnlactiv li a {
	font-size: 12px;
	line-height: 18px;
}
.leftpnlactiv li {
	padding: 2px 0;
}
.leftpnlactiv h6 {
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 6px;
}

.filterProductLstTop li::before {
  height: 24px;
}

.filterRghtbtm .filterProductLst {
  margin-top: 1px;
}

.lftuser {
  padding-left: 20px !important;
}

.leftpnlactiv {
  padding: 10px 15px 10px 25px;
}





}

@media only screen and (max-width: 1500px) {

  .leftPart,
  .filterRght {
    max-width: 180px;
  }

  .filterProductLnk li button {
    height: 50px;
  }

  .filterProductLstTop li button {
    padding: 8px 0 8px 25px;
  }

  .filterProductLstTop li:nth-child(2n) button {
    padding-right: 25px;
  }

  .filterProductLstTop li button::before {
    width: 11px;
    height: 11px;
    left: 7px;
  }

  .filterProductLstTop li:nth-child(2n) button::before {
    right: 7px;
  }

  .filterProductLstTop li::before {
    height: 22px;
  }

  .dashbordModal .modal-dialog {
    max-width: calc(100% - 360px);
  }

  .modalRegister .modal-content, .buyerModalOpen .modal-content{max-width: calc(100% - 360px);}
  .rghttootipMsg {max-width: 200px; padding: 10px;}
  .rghttootipMsg h4 {font-size: 15px; line-height: 20px; margin: 0 0 8px;}
  .rghttootipMsg p {font-size: 13px; line-height: 20px; margin-left: 15px;}
  .tooltipIcon{width: 40px;}
  .rghttootip {margin-left: 20px;}
  
/* __NEW__ */
.registermodal .usermodalbtm {margin: 30px 0 0;}
.registermodal .loginBtn input[type="submit"] {margin: 20px 0 0;}
.registermodal .frmData {margin: 15px 0 0;}
.registermodal .registerBtn {margin: 20px 0 0;}

/*_____ForgotPass_____*/
.fogotPassModal .modal-content{max-width: calc(100% - 360px);}


/* ___29_5_23 */
.carDelarRghtLst li {
  padding: 12px 10px 15px 40px;
  min-height: 60px;
}
.carDelarRghtLst li i {margin-right: 5px; width: 27px;}

 .container {
  max-width: 1050px;
}



}

@media only screen and (max-width: 1400px) {
 
  .leftpartbtm li img {
    width: 20px;
    }
    .mbilfltrnon img {
      width: 20px;
  }
    .lftprtLgo {
      min-height: 90px;
  }
  .container {
    max-width: 950px;
  }

  body {
    padding: 0 0 55px;
  }

  .productbxbtm h3 {
    font-size: 12px;
    line-height: 15px;
    width: 100%;
  }
  .filterProductLstIner{
    padding: 110px 0 0;
    height: calc(100% - 45px);
  }
  .listflterCtgry .form-group label {
    padding: 0px 15px 
  }

  .filterProductLstTop li button {
    padding: 0px 0 0px 25px;
}
.subIner{
  padding: 170px 0 0;
}
.filterRghtBtn {
  bottom: 5px;
}
.customRadio label {
  font-size: 24px;
  line-height: 32px;
}
.filterRghtTop {
  min-height: 60px;
}
.listflterCtgry .form-group {
  max-height: 22px;
}
.fogotPassModal .usermodaltop {
  margin: 40px 0 0;
}
.leftpnlactiv li a {
	font-size: 11px;
	line-height: 16px;
}

/* .leftpnlactiv {
  padding: 10px 15px 10px;
} */
.listingLstTop li, .listingLstBtm li {
  line-height: 14px
}
.listingLstTop li em, .listingLstBtm li em {
  font-size: 12px;
}
.listingLstTop li b,.listingLstBtm li b {
  font-size: 13px;
}   
.listingDtilslftSldr > div{
  max-height: 402px;
}
.listingDtilslftSldr > div a {
  height: 402px;
}
.listingLst{
  min-height: 402px;
}
.productbxbtm strong {
  font-size: 13px;
}
.filterRght, .leftPart {
  max-width: 200px;
}
.sortpnl figure img {
  width: 70px;
}
.listingDtilsRtbtm h5 {
  font-size: 13px;
}
/* .yrfuel p {
  font-size: 23px;
} */
}

@media only screen and (max-width: 1300px) {
 
  .leftpartbtm li img {
    width: 22px;
    }
  .leftPart,
  .filterRght {
    max-width: 170px;
  }

  .filterProductLstTop li button {
    padding: 8px 0 8px 18px;
    font-size: 9px;
    line-height: 15px;
}

  .filterProductLstTop li button::before {
    width: 8px;
    height: 8px;
    left: 6px;
    border-width: 2px;
  }

  .filterProductLstTop li:nth-child(2n) button {
    padding-right: 20px;
  }

  .filterProductLstTop li:nth-child(2n) button::before {
    right: 6px;
  }

  .listflterCtgry .form-group label {
    font-size: 12px;
    line-height: 15px;
    padding: 8px 12px 7px;
  }

  .listflterCtgry .form-group label::before {
    width: 11px;
    height: 11px;
    right: 7px;
    border-width: 2px;
  }

  .filterRghtBtn li a {
    font-size: 10px;
    line-height: 14px;
    height: 30px;
    padding: 0 8px;
  }
  .filterRghtBtn li img {
    width: 20px;
  }

  .filterRghtBtn li {
    margin-right: 5px;
  }

  .filterRghtBtn {
    bottom: 15px;
  }

  .filterRght {
    padding: 0 0 65px;
  }

  .filterProductLnk li button {
    height: 50px;
    font-size: 12px;
}

  .filterRghtTop {
    min-height: 88px;
  }

  .filterRghtTop .media h3 {
    line-height: 20px;
    font-size: 16px;
  }

  .filterRghtTop .media>figure {
    margin: 4px 10px 0 0;
    width: 30px;
  }

  .filterProductLstIner {
    padding: 150px 0 0;
    height: calc(100% - 70px);
  }

  .lftprtLgo {
    min-height: 88px;
  }

  .lftprtLgo img {
    width: 111px;
  }

  .lftuser {
    padding: 20px 0;
  }

  .lftuser .media h4 {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 1px;
  }

  .lftuser .media strong {
    font-size: 13px;
    line-height: 15px;
  }

  .lftuser .media>figure {
    margin-right: 10px;
    width: 46px;
  }

  .leftwhy .media h4 {
    font-size: 13px;
    line-height: 20px;
    margin: 0px 0 1px;
  }

  .leftwhy .media strong {
    font-size: 16px;
    line-height: 22px;
  }

  .leftwhy .media>figure {
    margin-right: 10px;
    width: 37px;
  }

  .leftpartbtm li a {
    font-size: 14px;
    line-height: 19px;
  }

  .leftpartbtm li {
    padding: 7px 0;
  }

  .desktopAdspnl h1 {
    font-size: 50px;
    line-height: 55px;
  }

  .desktopAdspnl {
    min-height: 240px;
  }

  .dashbordModal .modal-dialog {
    max-width: calc(100% - 300px);
  }

  .frmData input[type="text"],
  .frmData input[type="password"] {
    font-size: 23px;
    line-height: 27px;
    height: 40px;
  }

  .frmData i {
    right: 10px;
    width: 29px;
  }

  .frmData {
    margin: 25px 0 0;
  }

  .userLoked img {
    width: 58px;
  }

  .usermodal {
    max-width: 322px;
  }

  .loginBtn input[type="submit"] {
    margin: 23px 0 0;
  }

  .registerBtn,
  .loginBtn input[type="submit"] {
    line-height: 30px;
    width: 233px;
    font-size: 19px;
    width: 183px;
  }

  .loginBtn>em {
    margin: 15px 0 0;
    font-size: 19px;
    line-height: 23px;
  }

  .usermodalbtm>em {
    font-size: 19px;
    line-height: 23px;
    padding: 0 0 7px;
  }

  .usermodalbtm figure {
    margin: 9px 0 0;
  }

  .usermodalbtm figure img {
    width: 39px;
  }

  .usermodalbtm {
    margin: 80px 0 0;
  }

  .btnClose img:last-child {
    display: inline-block;
    width: 30px;
  }

  .btnClose img:first-child {
    display: none;
  }

  .btnClose {
    top: 14px;
    right: 14px;
  }
  .modalRegister .modal-content, .buyerModalOpen .modal-content{max-width: calc(100% - 300px);}
  .closeButton img:last-child, .closeButtonTwo img:last-child {display: inline-block;}
  .closeButton img:first-child, .closeButtonTwo img:first-child {display: none;}
  .closeButton, .closeButtonTwo {top: 14px; right: 14px; width: 30px;}
  .selerDelrTopbtm li > button { width: 235px; height: 160px; font-size: 18px; line-height: 24px;}
  .selerDelrTopbtm li > button img { width: 65px;}
  .selerDelrTopbtm li {padding: 0 30px; margin: 50px 0 0;}
  
/*_____ForgotPass_____*/
.closeButtonfogot img:last-child {display: inline-block;}
.closeButtonfogot img:first-child {display: none;}
.closeButtonfogot{top: 14px; right: 14px;width: 30px;}
.fogotPassModal .modal-content{max-width: calc(100% - 300px);}


/* ___29_5_23 */
.carDelarRght .filterRghtTop .media h3 {font-size: 14px; line-height: 20px;}
.carDelarRght .filterRghtTop .media > figure {margin: 0 0 4px; width: 38px;}
.carDelarRghtLst li span { line-height: 16px; font-size: 13px;}
.carDelarRghtLst li i { margin-right: 5px; width: 20px;}
.carDelarRghtLst li { padding: 10px; min-height: 53px;}
.carDelarRghtLst li em {font-size: 15px; line-height: 20px;}
.carDelarRghtBtmMsg span { font-size: 14px; line-height: 20px;}
.carDelarRghtBtmMsg {bottom: 10px; padding: 5px;}
.listingLstTop li, .listingLstBtm li{font-size: 14px;}
.listingHedinr h1{font-size: 20px; line-height: 25px;}
.listingHedinr strong{font-size: 22px; line-height: 26px;}
.listingHedinr > a {margin-right: 25px;width: 61px;height: 61px;}
.listingHedinr > a img{width: 30px;}

.leftpartbtm li a {padding: 7px 5px 7px 10px; font-size: 13px; line-height: 18px;}
.leftpartbtm li a i {margin-right: 7px; width: 20px;}

.listflterCtgry .form-group {
  max-height: initial;
}

.listflterCtgry .form-group label {
  font-size: 9px;
  line-height: 12px;
}
.container {
  max-width: 850px;
}

}

@media only screen and (max-width: 1200px) {

  .container {
    max-width: 850px;
  }

/* ___29_5_23 */
/* .carDetilsImge, .listingLst {max-height: 300px;} */
.listingDtilsRtbtm h5 {font-size: 13px;line-height: 16px;}
.listingDtilsRtbtm li img{width: 40px;}

.ctrgyList .saveDelar{width:40px;}
}

@media only screen and (max-width: 1199px) {

  .container {
    max-width: 690px;
  }

  .productSec .row>div {
    width: 33.33%;
  }

  .desktopAdspnl h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .searchfltr button {
    font-size: 17px;
    line-height: 23px;
  }

  .blankpnl h4 {
    font-size: 20px;
    line-height: 25px;
  }

  .blankpnl figure img {
    width: 200px;
  }
  .sortpnl figure img{
    width: 65px;
    }

  .selerDelrTopbtm li > button {width: 205px; height: 140px; font-size: 16px; line-height: 22px;}
  .selerDelrTopbtm li > button img { width: 60px;}
  .selerDelrTopbtm li {padding: 0 20px; margin: 40px 0 0;}
  .rghttootip{bottom: 0px;left: 0px;}
  .rghttootipr,.rghttootipe{width: auto;margin: 0;}
  .rghttootipa{top:0px;right: 0px;}
  .registermodal .loginBtn input[type="submit"] {height: 45px; margin: 35px 0 0;}
  .registermodal .usermodalbtm {margin: 0;}
  .registermodal .registerBtn {margin: 30px 0 0; height: 45px;}
  .rghttootipMsg {
    max-width: 322px;
    width: 322px;
    margin: 0 auto;
    position: absolute;
    bottom: -200px;
    left: -10px;
    right: 0px;
}
.rghttootipMsg p {
  font-size: 10px;
  line-height: 12px;
  margin-left: 0;
}
.rghttootip.rghttootipr {
  display: block;
  position: absolute;
  width: 29px;
  height: 28px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  /* background: red; */
}
.rghttootip.rghttootipr button.tooltipIcon {
  width: 100%;
}
.creaditfrom{position: inherit;}
.creaditfrom .rghttootipa {
  bottom: inherit;
  left: inherit;
  top: 0px;
  right: 0;
  width: 100%;
  height: 50px;
}
.creaditfrom .rghttootipa button.tooltipIcon {
  position: absolute;
  /* right: 30px; */
  top: 35px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rghttootipa .rghttootipMsg {
  width: calc(100vw - 60px);
  left: 0;
  bottom: -52px;
  right: 0;
}
.creaditfrom .rghttootipa button.tooltipIcon img {
  width: 30px;
}
/* ___29_5_23 */
.carDetilsImge h4 {line-height: 25px; font-size: 20px;}
.listingHedinr h1 {font-size: 16px; line-height: 18px; padding: 0;  text-align: left;  height: 50px;  padding-left: 15px;  padding-right: 15px;}
.listingHedinr > a { margin-right: 15px; width: 50px; height: 50px;}
.listingHedinr > a img {width: 28px;}
.listingDtilsRtbtm li{padding: 10px; text-align: center;}
.listingDtilslftSldr .owl-dots{bottom: 5px;}
.listingLstTop li, .listingLstBtm li { font-size: 13px; padding: 2px 5px;}
.listingLstTop li::before, .listingLstBtm li::before {left: 5px; right: 5px;}
.ShowBtn i{position: static; margin-left: 5px; width: 20px;}
.ShowBtn {font-size: 13px; line-height: 17px;}

.chkbox-optin {margin: 0px auto 0;}
.seller-type-pop {min-height: auto; margin-bottom: 50px;}
.stear-icon {max-width: 100%; height: 95px;}
.chosefrm {margin: 20px 0 0;}
.chosemk {font-size: 20px;line-height: 25px;padding: 0 33px;}
.chosefrm i{width: 24px; right: 0px;}
.chosefrm i img{max-width: 100%;}
.hedrnxtbck img {width: 40px;}
.iputprice {height: 100px;font-size: 19px;line-height: 25px;padding: 0 25px;}
.seller-type-pop .row > div:last-child::before {height: 240px;}
.twochkline .customRadio label {font-size: 17px;line-height: 25px;}
.twochkline {padding: 13px 0;}
.chkbox-optin .customRadio {width: 50%; margin: 0 0;}
.customRadio input[type="radio"] + label::before {top: 1px;}
.twochkline > .customRadio input[type="radio"] + label::before {height: 20px; width: 20px;}
.twochkline .customRadio:nth-child(2) input[type="radio"] + label {padding: 0 33px 0 0;}
.twochkline > .customRadio input[type="radio"] + label::after {top: 6px;left: 4px;}


.CAR_DEALERSldrSec .carDetilsImge img {height: auto;}

}


@media only screen and (max-width: 991px) {

  .container {
    max-width: 100%;
  }

  .desktopAds {
    display: none;
  }

  .mobileAds {
    width: 100%;
    display: block;
    margin: 120px 0 0;
  }

  .mobileAdspnl {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #202629;
    max-height: 210px;
    overflow: hidden;
  }
  .mobileAdspnl img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobileAdspnl h1 {
    color: #575757;
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
  }

  .leftPart {
    display: none;
  }

  .productSec .row {
    margin: 0 -12px;
  }

  .productSec .row>div {
    padding: 0 12px;
  }

  body {
    padding: 0 0 120px;
  }

  .mobileTphder {
    display: block;
    background: #202629;
    padding: 25px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
  }

  .mobileTphderinr {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menuIcon {
    border: 0;
    background: none; padding: 0;
  }
  .mobileTphderinr{
    position: relative;
  }
  .mobleHdrLgo{
    position: absolute; left: 50%; transform: translateX(-50%);
  }

  .mobleHdrLgo {
    display: inline-block;
  }

  .searchfltr {
    display: none;
  }

  .mobilehder {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: block;
    background: #CED4D8;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  .closeMnu {
    position: absolute;
    top: 14px;
    right: 14px;
  }
  .closeMnu img{
    width: 30px;
  }

  .mobilehderbtm {
    width: 100%;
    background: #202629;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobilehderbtm h3 {
    color: #CED4D8;
    font-weight: 700;
    font-size: 27px;
    line-height: 24px;
    margin: 0 0 2px;
  }

  .mobilehderbtm strong {
    color: #CED4D8;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }

  .mobilehderbtm .media {
    align-items: center;
  }

  .mobilehderbtm .media>figure {
    width: 74px;
    margin: 5px 0px 0 0;
  }

  .mobilehdertop {
    width: 100%;
    height: calc(100% - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
  }

  .mobilehdertop li a {
    color: #202629;
    display: inline-block;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
  }
  .mobilehdertop li img{
    width: 26px;
  }
  .mobilehdertop li a:hover {
    color: #000;
  }

  .mobilehdertop li {
    margin: 25px 0;
  }

  .openMenu .mobilehder {
    opacity: 1;
    pointer-events: visible;
  }

  .botomBar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: block;
    width: 100%;
  }

  .botomBar ul {
    display: flex;
    align-items: center;
  }

  .botomBar li {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 1px solid #CED4D8; */
  }
  .botomBar li:first-child{
    border-right: 1px solid #CED4D8;
  }
  .botomBar li a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #202629;
    width: 100%;
    color: #CED4D8;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    min-height: 90px;
  }

  .botomBar li:last-child i {
    border: 0;
    margin: 0 12px 0 0;
  }

  .botomBar li:first-child i {
    margin: 0 0 0 12px;
  }

  .filterRght {
    opacity: 0;
    pointer-events: none;
    z-index: 9999;
    visibility: hidden;
  }

  .openFilter .filterRght {
    opacity: 1;
    pointer-events: visible;
    max-width: 100%;
    visibility: visible;
  }

  .filterRghtBtn li {
    margin-right: 45px;
  }

  .filterRghtBtn li a {
    padding: 0 20px;
    width: auto;
  }

  .closeMnuTwo {
    position: fixed;
    top: 0px;
    right: 0;
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: space-between;
    pointer-events: none; width: 100%; min-height: 88px; background: #202629; z-index: 9;
    border-bottom: 1px solid #CED4D8; padding: 0 30px;
  }
  .closeMnuTwo li{padding: 0 15px;}

  .mbileTopBtn{
    display: inline-flex;
    color: #333333;
    text-transform: uppercase;
    background: #F8B756;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    width: 196px;
    height: 44px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .mbileTopBtn:hover{
    opacity: 0.75; color: #333333;
  }

  .openFilter .closeMnuTwo {
    opacity: 1;
    pointer-events: visible;
  }

  .dashbordModal .modal-dialog {
    max-width: calc(100%);
  }
  .frtwobtn{
    display: flex;
  }
  .frtwobtn a{
    background: #51575A;
    font-size: 14px;
    width: 102px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-left: 15px;
    font-weight: 700;
  }
  .frtwobtn a:last-child{
    background: #F8B756;
    color: #333;
  }
  .frtwobtn a:hover {
    opacity: 0.75;
  }
  .mbilfltrnon{
    display: none;
  }

  .filterRghtTop {
    justify-content: left;
  }
  .customRadio label {
    font-size: 22px;
    line-height: 30px;
  }

  .modalRegister .modal-content, .buyerModalOpen .modal-content{max-width: calc(100%);}

  /*_____ForgotPass_____*/
.fogotPassModal .modal-content{max-width: calc(100%);}
.fogotPassModal .registermodal .loginBtn input[type="submit"]{margin: 20px 0 0;}
.fogotPassModal .usermodaltop{margin: 40px 0 0;}

.filterRghtBtn{
  display: none;
}

.userLoked img {
  width: 52px;
}
.frmData {
  margin: 21px 0 0;
}
.frmData input[type="text"], .frmData input[type="password"]{height: 35px;font-size: 19px;
line-height: 23px;}
.loginBtn input[type="submit"] {
  margin: 21px 0 0; padding: 0; line-height: 40px; font-size: 17px;
  width: 165px;
}
.loginBtn > em {
  margin: 12px 0 0;
  font-size: 15px;
  line-height: 20px;
}
.usermodalbtm > em {
  font-size: 17px;
  line-height: 20px;
  padding: 0 5px 5px; display: inline-block; 
}
.usermodalbtm figure {
  margin: 7px 0 0;
}
.usermodalbtm figure img {
  width: 35px;
}
.usermodalbtm .registerBtn {
  margin: 25px 0 0; line-height: 40px; font-size: 17px;
  /* width: 165px; */

}
.usermodalbtm .registerBtn.trigger {
  margin: 7px 0 0;
}

/* ___29_5_23 */
.delerDesktop{display: none;}
.delermobile{display: block; margin: 100px 0 0;}
.listingHed {margin: 15px 0 0;}
.listingDtilslftSldr .whishCount{
  max-width: 28px;
  bottom: 10px;
}
.whishCount i{font-style: normal;}
.listingDtilslftSldr .whishCount span{
  font-size: 11px;
}
.listingDtilsRtbtm{margin: 20px 0;}
.listingLstTop li, .listingLstBtm li {font-size: 13px;padding: 5px 6px 5px 13px;}
.listingDtilsRtbtm li{padding: 0; background: none !important; min-height: auto; box-shadow: inherit; position: relative;}
/* .listingDtilsRtbtm li:first-child h5{color: #989898;} */
.listingDtilsRtbtm li::before{position: absolute; content: ""; width: 1px; height: 72px; border-right: 1px solid #989898;
right: 0; top: 50%; transform: translateY(-50%);}
.listingDtilsRtbtm li:last-child::before{content: none;}

.showModalClose{width: 25px;}

.delerbotomBar{display: block; position: fixed;bottom: 0;left: 0;background: #202629;width: 100%;z-index: 999;}
.delerbotomBar ul{display: flex;}
.delerbotomBar li{width: 50%; border-right: 1px solid #CED4D8;}
.delerbotomBar li a{color: #CED4D8; font-weight: 700; font-size: 15px; line-height: 21px; text-align: center;
display: flex; flex-direction: column; align-items: center; padding: 10px; }
.delerbotomBar li:last-child{border: 0;}
.frmData select::placeholder{text-align: center;}
.frmData option{text-align: center;}
.CAR_DEALER {margin: 124px 0 0;}
.CAR_DEALER .listingHedinr > a{display: none;}
.CAR_DEALER .listingHedinr h1 > em{display: none;}
.CAR_DEALERSldrads{display: block; background: #202629; display: flex; align-items: center; justify-content: center;
/* min-height: 210px; padding: 15px; */
}
.CAR_DEALERSldrads h4{color: #575757; font-weight: 400;font-size: 33px;line-height: 40px;}
.CAR_DEALERSldrSec{margin: 15px 0 0;}


.mblmarg{margin: 92px 0 0;}
.mblsvlsthdr{margin-top: 92px;}
.activeInactive{margin: 92px 0 0;}
/* .CAR_DEALERSldrSec .row{display: none;} */
.listingDtilsRt .detailedDescrip {
  margin-top: 10px;
  min-height: 200px;
}
.CAR_DEALERSldrSec .row.activeListing{display: flex;}
/* .ACTIVE_LISTINGS i{display: none;} */
.ACTIVE_LISTINGS,.activeListing .selerListinghed{margin: 0;}

.ctrgyList{display: flex; margin: 20px 0;}
.ctrgyList ul{display: flex; width: 100%;}
.ctrgyList li{display: inline-flex; width: 25%;flex-direction: column; align-items: center; justify-content: center;
position: relative;}
.ctrgyList li em{font-style: normal; color: #989898; font-weight: 700;font-size: 14px;line-height: 18px;}
.ctrgyList li i{font-style: normal;margin-top: 10px;}
.ctrgyList li::before{position: absolute; content: ""; width: 1px; right: 0; height: 70px; border-right: 1px solid #989898;}
.ctrgyList li:last-child::before{content: none;}
/* .activeListing{margin-top: 90px;} */

.aftrmenupop {
	display: block !important;
	max-width: 100%;
	margin-top: 50px;
}
.leftnew{
  align-items: flex-start;
  justify-content: inherit;
  display: flex !important;
  flex-direction: inherit;
  margin-top: 0;
  padding: 30px 0 0;
}
.popmnu{
  position: relative;
}
.popmnu .modal-header{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.mobileft{
  width: 100%;
}
.aftrlogbg{
  background: #202629 !important;
}
.leftpnlactiv {
    max-width: 100%;
    margin: 0 auto;
    padding-left: 25%;
}
.topbdrnon{
  border-top: none;
}
.filterProductLnk li button {
  font-size: 20px;
  height: 65px;
}
.lftuser {
  padding-left: 30% !important;
}

.paypalmodal {
  padding: 20px 0;
}
.margTop{
  margin-top: 50px!important;
}
.motoTypeByke{
  height: auto!important;
}
/* .detailsrentbtn{
  z-index: 9;
  bottom: inherit;
  left: 1px;
  top: 1px;
  padding: 10px 12px;
  font-size: 14px;
} */
}


@media only screen and (max-width: 990px) {
  .menuIcon{width:37px}
  .mobleHdrLgo{width:175px;}
  .botomBar li:first-child i, .botomBar li:last-child i {
    width: 38px;
}
.mobilehdertop li a i {
  border-bottom: 1px solid rgba(0,0,0,.33);
  border-top: 1px solid rgba(0,0,0,.33);
  display: inline-block;
  height: 37px;
  line-height: 36px;
  margin-right: 15px;
  padding: 0;
}
.cpyrgt p, .cpyrgt span {
  display: block;
  text-align: center;
}
.cpyrgt p {
  color: #ced4d8;
  font-size: 15px;
}
.cpyrgt span {
  color: #f8b756;
  font-size: 30px;
  margin-bottom: 5px;
}
.dashbordModal {
  z-index: 9999;
}
.closeMnuTwo li:first-child img, .closeMnuTwo li:last-child img {
  width: 30px;
}
.listflterCtgry li {  
  padding: 4.5px 0 4.5px;
}
.listflterCtgry .form-group label {
  padding: 0;
  font-size: 15px;
}
.filterProductLstTop li button {
  font-size: 15px;
  line-height: 15px;
  padding: 7px 0 7px 20px;
}
.filterProductLstTop li:nth-child(2n) button {
  padding-right: 20px;
}
.filterProductLstTop li:before {
  height: 40px;
}
/* .filterProductLstTop li:nth-child(2n) button {
  padding-right: 40px;
} */

.imageUpdateModal{
  display: block;
}
.pnctbtn img{
  max-width: 25px;
  filter: brightness(0) invert(0);
  width: auto !important;
  height: auto !important;
}
.pnctbtn{
  position: absolute;
  right: 0;
  top: 0;
  background: #F8B756;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.moblhid{
  display: none !important;
}
.dskHide{
  display: block !important;
}

.CAR_DEALERSldrSec .carDetilsImge img {
  height: 250px;
  /* height: auto; */
  object-fit: cover;
}
}

@media only screen and (max-width: 767px) {



  .gridBtn {
    display: block;
    margin: 25px 0 0;
  }

  .filter-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter-buttons>div {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: #989898;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin: 0 11px;
    cursor: pointer;
  }

  .list-view-button i {
    margin-left: 10px;
  }

  .grid-view-button i {
    margin-right: 10px;
  }

  .list-view-button::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    right: -12px;
    width: 1px;
    height: 35px;
    border-right: 1px solid #989898;
  }

  .productSec.list-view-filter .row>div {
    width: 100%;
  }

  .productSec.grid-view-filter .row>div {
    width: 50%;
  }

  .productbxbtm h3 {
    font-weight: 500;
}

  .productSec.list-view-filter .productbxtopimg {
    min-height: 152px;
  }

  .productSec.list-view-filter .productbxtoprtlst {
    max-width: 85px;
  }

  .productbxtoprtlst ul {
    width: 95%;
  }

  .productSec.list-view-filter .productbxtoprtlst li {
    padding: 8.2px 2px;
    font-size: 12px;
  }

  .productSec.list-view-filter .productbxbtm strong {
    width: 147px;
    font-size: 18px;
  }


  .productSec.list-view-filter .productbxbtm::before {
    right: 119px;
  }
  .productbxtoprtlst li {
    padding: 3.5px 0 3.5px 5px;
  }
  .mobileTphder {
    padding: 12px 0 14px;
  }

  .mobleHdrLgo {
    width: 175px;
  }

  .mobileAds {
    margin: 105px 0 0;
  }

  .botomBar li a {
    font-size: 20px;
    line-height: 25px;
    min-height: 65px;
  }

  .botomBar li:first-child i {
    width: 38px;
  }

  .botomBar li:last-child i {
    width: 38px;
  }

  body {
    padding: 0 0 95px;
  }
  
  .twordobx p {
    font-size: 17px;
  }
  .bmwpop{
    margin-bottom: 20px;
  }
  .bmwpop .row > div:last-child::before {
    display: none;
  }
  .seletfrm {
    margin: 15px auto;
  }
  .seller-type-pop {
    width: 100%;
    min-height: inherit;
  }
  .chosemk {
    font-size: 20px;
    padding: 0 20px;
  }
  .middlearw {
    min-width: 60px;
  }
  .chosefrm i img{
    max-width: 20px;
  }
  .middlearw img {
    max-width: 50%;
  }

  .closeMnuTwo li {
    padding: 0 8px;
  }
  .closeMnuTwo li:last-child img{width: 30px;}
  .closeMnuTwo li:first-child img{width: 30px;}

  /* ___29_5_23 */
.ACTIVE_LISTINGS, .activeListing .selerListinghed{padding: 11px 8px;}
.CAR_DEALER {margin: 100px 0 0;}

/* 19062023 */
.productSec.list-view-filter .productbxtoprtlst {max-width: 120px;}
.productSec.grid-view-filter .productcolumn .productbx .productbxtoprtlst{max-width: 101px;}
.productSec.grid-view-filter .productcolumn .productbx .productbxbtm strong{width: 167px;}
.productSec.grid-view-filter .productcolumn .productbx .productbxbtm:before{right: 101px;}
.productSec.grid-view-filter .productcolumn .productbx .productbxbtm h3{width: 100%;}

/* .ACTIVE_LISTINGS  i{display: none;} */
.listingHedinr h1, .usermodalbtm > em{font-weight: 500;}
.location-poup .pricicon{display: none;}

#addmodal2 .seller-type-pop .row > div::before{content: none;}
#addmodal3 .seller-type-pop .row > div::before{content: none;}
#addmodal3 .selrtypoup{margin-top: 0px;}
.leftul li a {display: block;}
.selrtypoup{margin-top: -50px;}
.numberCount {padding: 0 0 0px; }
.fusClor .stear-icon { height: 45px;}
.fusClor .chosefrm {margin: 15px 0 0;}
.fusClor .rightBx .seletfrm{margin-top: 0; margin-bottom: 0;}
.fusClor .chkbox-optin {max-width: 290px;}
.fusClor .seller-type-pop {margin-bottom: 25px;}
.fusClor .seller-type-pop .row{width: auto;}
.mrtop0{
  margin-top: 0 !important;
}
/* .cntrlst{
  display: flex;
  justify-content: center;
}
.cntrlst > ul{
  width: auto;
}
.leftpnlactiv{
  padding-left: 0;
  padding-right: 0;
} */
.carselct::before{display: none;}
.mblprfdl i{
  width: 35px;
}
.lftuser {
  padding-left: 24% !important;
}
.leftpnlactiv {
  padding: 15px 15px 15px;
  padding-left: 24%
}
.leftpnlactiv h6 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
}
.leftpnlactiv li {
    padding: 8px 0;
}
.leftpnlactiv li a {
    font-size: 16px;
    line-height: 20px;
}
.leftpnlactiv li a i img {
    max-width: 15px;
}
.fusClor14   {
    padding-top: 0px;
}
.chosefrm i {
    right: 5px;
}

.mblimgcbtr{
  padding-top: 50px;
  justify-content: center;
}
.paypal-buttons {
  width: 80vw !important;
}

.customCheckbox input[type="checkbox"] + label:before {
  top: 12px;
  left: 24px;
}
.customCheckbox input[type="checkbox"] + label:after {
  top:17px;
  left: 28px;
}
.mblMrgn{
  margin: 50px auto;
}
.spacediv {
	padding: 0 30px !important;
}
}

@media only screen and (max-width: 575px) {
  .listingDtilslftSldr .imgCount span{font-size: 14px; }
  .btnClose {
    top: 14px;
    right: 14px;
    padding: 0;
    width: 30px;
  }
  .payCls{
    top: 25px;
    right: 40px;
  }
  .mobilehdertop{
    height: calc(100% - 220px);
  }
  .mobilehderbtm{
    min-height: 220px;
    align-items: start;
    padding-top: 20px;
  }
  .dashbordModal{
    z-index: 9999;
  }
  .openFilter .filterRght {
    overflow-y: auto;
  }
  /* .filterProductLstIner  {
   overflow-y: auto !important;
} */
  .productbxtoprtlst li:nth-child(4) {
    font-size: 5px;
}
.filterProductLstTop li:nth-child(2n) button {
  padding-right: 80px;
}
.filterProductLstTop li:nth-child(2n) button::before {
  right: 22px;
}
.listflterCtgry .form-group label::before {
  width: 18px;
  height: 18px;
  right: 22px;

}
.filterProductLstTop li button::before {
  width: 18px;
  height: 18px;
  left: 22px;
}
.filterProductLstTop li button {
  font-size: 14px;
  line-height: 17px;
  padding: 18px 80px 18px;  
}
  .selerDelrTopbtm li {padding: 0 15px;margin: 30px 0 0;}
  .selerDelrTopbtm li > button {width: 185px;height: 130px;font-size: 15px;line-height: 20px;}
  .selerDelrTopbtm li > button img {width: 55px;}
  .selerDelrTop img {width: 70px;}
  .blankpnl figure img {
    width: 77px;
}
.sortpnl figure img{
  width: 60px;
  }
.allopfiltr{
  margin-top:50px;
}
.blankpnl .customRadio {
  margin: 0px 0 4px;
}
.listflterCtgry .form-group {
  max-height: inherit;
}
.listflterCtgry .form-group label {
  font-size: 14px;
  line-height: 17px;
  padding: 13px 12px 13px;
}

/* ___29_5_23 */
.ctrgyList li{width: 50%;}

.mobilehdertop li a i{margin-right: 10px;}
.listingHedinr strong {
	max-width: 90px;
  font-size: 17px;
}
.stear-icon {
	max-width: 90px;
	height: 90px;
}
.seller-type-pop .row > div:last-child::before {
	border-top: 1px solid rgba(32, 38, 41, 0.33);
	border-left: none;
	width: 80%;
	height: 1px !important;
	margin: 0 auto;
	left: 0;
	right: 0;
}
.fusClor14 .seller-type-pop .row > div:last-child::before{border-top: 0px solid rgba(32, 38, 41, 0.33);}
.seller-type-pop .row {
	width: auto;
}
.chosemk2 {
	font-size: 18px;
	line-height: normal;
}
.fulebx .row > div::before,.fulebx .row > div:last-child::before {
  border: 0px solid rgba(32, 38, 41, 0.33);
}

.yrfuel .stear-icon {
	max-width: 45px;
	height: 45px;
}
.upldlnk {
	font-size: 18px;
}
.frmData input[type="text"], .frmData input[type="password"], .frmData select, .frmData input[type="tel"] {
	font-size: 18px;
	line-height: normal;
  height: 40px;
}
.assignbtn {
	font-size: 17px;
  height: 42px;
}

.automatic .customCheckbox input[type="checkbox"] + label {font-size: 16px; padding: 0 0 0 56px;}
.listingLstTop li, .listingLstBtm li {
	font-size: 11px;
	font-weight: 500;
}
.searchlft input[type="text"], .searchlft input[type="search"] {
	font-weight: 500;
	font-size: 20px;
}
.botomBar li a {
	font-size: 16px;
	line-height: 22px;
	min-height: 80px;
}
.botomBar li:first-child i {
	width: 30px;
}
.botomBar li:last-child i {
	width: 28px;
  /* margin: 3px 8px 0 0; */
}
.closeMnuTwo {
	justify-content: center;
}
.closeMnuTwo li:nth-child(2){
  padding: 0 30px;
}
.mobilehderbtm h3 {
	margin: 0 0 0;
}
.mobilehderbtm .media-object {
  padding-top: 5px;
  cursor: pointer;
}
.mobilehderbtm .media-object strong{
  max-width: 100px;
}
.mobilehderbtm .media-object strong img{
  max-width: 100%;
  width: auto;
}
.mobilehdertop li a i {
	margin-right: 15px;
	border-top: 1px solid rgba(0, 0, 0, 0.33);
	border-bottom: 1px solid rgba(0, 0, 0, 0.33);
	padding: 0;
	height: 37px;
	display: inline-block;
	line-height: 36px;
}
.mobilehdertop li a {
	font-size: 21px;
}
/* .mobilehdertop li:first-child a i img{
  opacity: 0.60;
} */
.usermodalbtm figure {
  display: none;
}
.usermodalbtm > em {
	border-top: 1px solid #000000;
	padding: 9px 0 9px;
  border-bottom: none;
}
.productbx {
	margin: 14px 0 0;
}
.saveseller img {
  width: 70px;
 
}
.productcolumn{
  margin: 14px 0 0;
}
.gridBtn {
	margin: 24px 0 10px;
}
.seletfrm {
	max-width: 270px;
}
.chosemk {
	text-align: left;
}
.twordobx {
	padding-bottom: 45px;
}
.elricon img {
	max-width: 78% !important;
}
#addmodal3 .twordobx p {
	margin-top: 0;
  margin-bottom: -12px;
}
.mrgnBtm p{
  margin-bottom: -12px !important;
}
.twordobx p {
  margin-top: 0;
}
.oksbmit {
	width: 120px;
	height: 46px;
	font-size: 20px;
}
#addmodal3 .stear-icon img {
	max-width: 60px !important;
}
.bmwpop .row > div:last-child::before {
	border-top: 1px solid rgba(32, 38, 41, 0.33);
	border-left: none;
	width: 80%;
	height: 1px !important;
	margin: 0 auto;
	left: 0;
	right: 0;
  display: block;
}
.selrtypoup img {
	max-width: 95px;
}
.selrtypoup .mtrcImg{max-height: 80px;}
.elricon {
	max-width: 78px;
	height: 78px;
}
.seller-type-pop .row > div {
	padding: 0px 0 0;
}
.pricicon p {
	font-size: 22px;
}
.priceinput {
	width: 85%;
  margin: 0 auto;
}
.okbtn {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px 0 15px;
	background: #202629;
}

.okbtn button{
  position: absolute;
  bottom: 0;
}

.iputprice {
  height: 50px;
  font-size: 20px;
}
.searchSec {
	margin: 24px 0 0;
}
.mobileAds {
	margin: 100px 0 0;
}
.pricicon p {
	text-transform: capitalize;
}
.customRadio label {
	font-size: 18px;
}
.customRadio input[type="radio"] + label::before {
	height: 20px;
	width: 20px;
	top: auto;
}
.customRadio input[type="radio"] + label::after {
	width: 10px;
	height: 10px;
	top: auto;
	left: 5px;
}
.customRadio input[type="radio"] + label {
	padding: 0 0 0 34px;
}
.otherdetl-popup img {
	max-width: 74px;
}
.usrpic {
	width: 40px;
	height: 40px;
}
.menuIcon{
  width: 37px;
}
.mbileTopBtn {
	width: 50vw;
}
/* .otherdetl-popup.allopfiltr {
  

  overflow-y: auto;
  height: calc(100vh - 100px);
} */
.other-fill-box{
  margin-bottom: 40px;
  text-align: start !important;
}



.other-fill{
  height: calc(100vh - 200px);
padding-left: 30px;
margin-top: 0px;
    overflow-x: hidden;
}
.seller-type-pop .customRadio input[type="radio"] + label::after {
	bottom: -37px;

}
.mobileTphderinr {
	height: 52px;
}
/* .listingDtilsRtbtm li:last-child img{width: 45px;} */
.listingDtilsRtbtm > ul > li:last-child h5{margin-top: 6px;}
.CAR_DEALER .listingHedinr strong {
	font-size: 14px;
max-width: 100px;
line-height: 18px;
}
.allrdiopop > div figure img {    max-width: 40px;}
.allcredit-sec {
	display: block;
	max-width: 80%;
  margin: 30px auto;
}
.credtbx a{
  margin-bottom: 30px;
  font-size: 20px;
}
.allrdiopop h4{
  font-size: 18px;
}
.creditinner h4 {
	font-size: 62px;
}
.creditinner span {
	font-size: 20px;
}
.creditblnc h6 {
	font-size: 14px;
}
.creditblnc h5 {
	font-size: 19px;
}
.creditblnc p {
	font-size: 14px;
}
.creditblnc hr {
	max-width: 68%;
}
.middlearw img{
  max-width: 35px;
}
.rgfulbtn {
	width: 100% !important;
}
.dashbordModal .modal-body {
  height: calc(50vh - 154px);
  /* align-items: flex-start; */
}
#addmodal3 .modal-body {height: auto!important;padding-top: 0;}
.TopPading0 > .modal-body{padding-top: 0;}
.TopPading0 > .modal-body > div > div.seller-type-pop:first-child{padding-top: 0;}

.abstbtn{
  position:absolute;
  top:20px;
  right: 20px;
  visibility: hidden;

}
.otherFilter  .modal-body {
  /* height: calc(50vh - 154px); */
  align-items: flex-start;
}

.customRadio input[type="checkbox"] + label:after {
  top: 7.6px;
  left: 4.87px;
}
.dashbordModal .selrtypoup {
  overflow-y: auto;
  display: flex;
  align-items: center;
  height: 100vh;
  padding-bottom: 107px;
}

#addmodal4 .selrtypoup {
  padding-bottom: 107px !important;
}

/* #pormotlist  .automatic{margin-top: 0px;} */
/* .dashbordModal .selrtypoup {padding-bottom: 0;} */
#addmodal20.dashbordModal .selrtypoup,#pormotlist.dashbordModal .selrtypoup {
  padding-bottom: 107px;
}
#upmodal8 .selrtypoup { padding-bottom: 200px;}
#upmodal8 .modal-body { overflow: hidden !important;}
#addmodal8 .selrtypoup { padding-bottom: 150px;}
.dashbordModal .mapfltr{
  align-items: flex-start;
}
#addmodal3 .selrtypoup{
  padding-bottom: 0px;
  height: 90vh;
  /* height: calc(100% - 80px); */
}
.scrlpop{
  overflow: hidden !important;
}
.flxtrt{
  align-items: start !important;
}
.flxtrt .seller-type-pop {
  margin: 0 !important;
  padding: 0;
}
.dashbordModal .mblcred{
  flex-direction: column;
  margin-top: 50px;
  padding-bottom: 180px;
  margin-top: 0;
  padding-top: 10px;
}
#pormotlist .allrdiopop > div figure img {
  max-width: 250px;
}
#pormotlist .allrdiopop > div figure{margin-bottom: 20px;}
#pormotlist .upascred {        
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;}
.imageUpdateModal{
  display: block !important;
}
.ovrfHide{
  overflow: hidden;
}


.listingHedinr strong em {
	
	right: 5px;
	bottom: 5px;
	font-size: 13px;
  line-height: 10px;
}
.bmwpop .row>div:first-child {
  padding-bottom: 0;
  padding-top: 20px;
}
.bmwpop .row > div:last-child{
  padding-top: 30px;
}
.bmwpop .row>div:last-child .twordobx {
  padding-bottom: 0;
}

.bmwpop {
  overflow: hidden;
}

.cpyrgt {
	text-align: center;
	padding: 0 30px;
}
.cpyrgt a{
  text-decoration: none;
  color: #CED4D8;
  font-size: 13px;
}
.cpyrgt span{
  text-align: center;
  display: block;
  color: #F8B756;
  font-size: 30px;
  margin-bottom: 5px;
}
.cpyrgt p{
  text-align: center;
  display: block;
  color: #CED4D8;
  font-size: 13px;
}
.mapopbx {
	flex-wrap: wrap;
}
.midlor {
	width: 100%;
}
.listingDtilslftSldr > div a {
  height: 350px;
}
.listingLst {
	justify-content: normal;
}
.listingLstTop ul{
  position: relative;
}
.listingLstTop ul::before{
	position: absolute;
	content: "";
	bottom: 0;
	left: 5px;
  right: 5px;
	border-bottom: 1px solid #989898;
	height: 1px;
}
.listingLst {
	min-height: inherit;
}
/* 19062023 */
.productSec.list-view-filter .productbxtoprtlst {max-width: 100px;}
.productSec.list-view-filter .productbxtoprtlst li{font-size: 11px;}
.productSec.list-view-filter .productbxbtm strong{width: 142px;}
.productSec.list-view-filter .productbxbtm::before{right: 100px;}
.productSec.list-view-filter .productcolumn .productbx{height: 100%; margin: 0px;}
.productSec.grid-view-filter .productcolumn .productbx{height: 100%; margin: 0px;}
.productSec.grid-view-filter .productcolumn .productbx .productbxbtm strong{width: 182px;}

.productSec.grid-view-filter .productcolumn .productbx .productbxtoprtlst{max-width: 90px;}
.productSec.grid-view-filter .productcolumn .productbx .productbxbtm:before{right: 90px;}

.otherdetl-popup img {max-width: 60px;}


#addmodal2 .selrtypoup{display: inline-block;padding-left: 20px; padding-right: 20px;}
#addmodal2 .twordobx {padding-bottom: 25px;}
#addmodal2 .seller-type-pop .row{margin: 0;}
#addmodal2 seller-type-pop .row > div {padding: 25px 0 0;}
#addmodal2 .seller-type-pop{margin: 0;}
#addmodal2 .modal-body{padding: 0px;}
#addmodal2 .customRadio input[type="radio"] + label::before {top: 2px;}
#addmodal2 .seller-type-pop .row > div::before{content: none; width: 100%;}
#addmodal2 .seletfrm { margin: 0 auto;}

/* .seller-type-pop .row > div{padding: 0;} */
#seller_type .seller-type-pop .row > div{padding: 40px 0 ;}
.twordobx{padding-bottom: 0;}
.seller-type-pop .row{margin: 0;}
.seller-type-pop{margin: 0; padding: 45px 0 0;}
.fusClor14 {
  padding-top: 0px;
}
#addmodal3 .seller-type-pop{margin: 0; padding: 0px 0 0;
  /* margin-top: -90px; */
}
#addmodal5 .seller-type-pop{margin: 0; padding: 0px 0 0;
  /* margin-top: -90px; */
}
#addmodal5 .modal-body{height: auto;}
/* #addmodal5 .selrtypoup{padding-bottom: 0;height: 90vh;} */
#addmodal5 .selrtypoup,#addmodal6 .selrtypoup {
  margin-top: 0px;
}
/* #addmodal5  .selrtypoup img {
  max-width: 60px !important;
} */
/* #addmodal6 .selrtypoup{padding-bottom: 0;height: auto;}
#addmodal6 .seller-type-pop{padding: 0px;}
#addmodal6 .selrtypoup{height: auto;} */
#addmodal6 .modal-body {
	height: auto;
}

.twordobx {padding-bottom: 0px;}
#addmodal7 .allrdiopop,#upmodal7 .allrdiopop{height: 100%; overflow: auto; padding: 0 15px;}
#addmodal7 .customRadio input[type=checkbox]+label:after,#upmodal7 .customRadio input[type=checkbox]+label:after{
  top: 8px;
}
#addmodal7 .allopfiltr .row{margin: 0;}
#addmodal7 .allopfiltr .row > div{padding: 0;}
#addmodal7 .otherdetl-popup .allopfiltr {margin: 25px 0 15px;}
#addmodal8 .selrtypoup{flex-direction: column;}
#addmodal8 .selrtypoup > .row {margin: 0 auto !important;max-width: 100% !important;width: 100%;text-align: center;padding: 0 30px;overflow-y: auto;}
#addmodal8 .selrtypoup > .row > div{padding: 0; width: 100%;}
/* #addmodal8 .selrtypoup > .row > div .cardpic img {width: auto;height: auto;object-fit: cover;max-width: 100%;} */
#addmodal8 .selrtypoup > .row > div .cardpic { width: 100%; height: auto; padding: 0 0;}
/* #addmodal8 .uplodbtn { padding: 10px 20px; margin: 35px auto 15px;} */
/* .oldPic .deletePic {
	right: -10px;
} */
.oldPic .deletePic img{
	max-width: 15px !important;
}
.oldPic .hompic img{max-width: 20px!important;}
.frmData textarea {
	font-size: 18px;
}
.frmData .lstNameIn{
  font-size: 18px;
  line-height: 20px;
  min-height: 75px;
}
.frmData .lstNameIn::placeholder{
  font-size: 18px;
  line-height: 20px;
}
.frmData textarea::placeholder{
  margin-top: 50px;
  font-size: 18px;
}

.modal-open{
  background-color: #CED4D8;
}


.autoSalon .twordobx{padding-bottom: 45px;}
.autoSalon .seller-type-pop .customRadio input[type="radio"] + label::after {height: 12px; width: 12px;}
.autoSalon .seller-type-pop .customRadio input[type="radio"] + label::after {bottom: -36px;}
.customMake .twordobx{padding-bottom: 45px;}

.listingDtilslftSldr > div{max-height: 300px;}
.listingDtilslftSldr > div a {height: 300px;}

.carselct{width: 50%;}
.fstcalink{flex-wrap: wrap;}
.carselct:nth-child(2)::before{content: none;}


.fusClor .seller-type-pop .row > div {padding: 20px 0 0;}
.fusClor .twochkline {padding: 10px 0;}
.fusClor .rightBx{padding-bottom: 12px !important;}
.padbtm{padding-bottom:35px !important;}
.noProduct .viditext{position: absolute; top: 50%; transform: translateY(-50%); padding: 0;}

.soldmodalCon h5 { line-height: 23px;}
soldmodalBtns button {padding: 5px 14px; font-size: 12px;}
.soldmodalBtns {margin: 20px 0 0;}

.imageUpdatePic{
  padding: 0 20px;
}
#upmodal2 .stear-icon {max-width: 45px; height: 50px;}
.upmodal .selrtypoup {
  margin-top: 0px;
  align-items: flex-start;
}
.upmodal .seller-type-pop{padding: 0px;}
#upmodal2 .seller-type-pop .row > div:last-child::before{
border-top: 0px;
}
/* .paypalBtn{
  padding-top:100px ;
} */
.fixedborderbox .maincardpic{height: 100px!important;}

.frmData textarea {
	min-height: inherit;
  height: 250px;
}


}


@media only screen and (max-width: 480px) {
  .selerDelrTopbtm ul {flex-wrap: wrap;justify-content: center;}
.selerDelrTopbtm li > button {width: 170px;height: 115px;font-size: 14px;line-height: 20px;}

/* ___29_5_23 */
/* .carDetilsImge, .listingLst, .listingDtilsRt .detailedDescrip {max-height: 235px;} */
.listingDtilslftSldr .owl-nav {padding: 0px 5px;}
.filterProductLst{
  padding-bottom: 60px;
}


#other_details figure {
  display: none;
}

.carDetilsImge{
  min-height: auto !important;
}

}

@media only screen and (max-width: 430px) {
  .productSec.list-view-filter .productbxtoprtlst li {
    padding: 8px 2px;
  }
  /* .gridBtn{display: none;}
.productSec.list-view-filter .productbxtopimg {min-height: 160px;} */

  .productSec.grid-view-filter .productbxtoprtlst {
    max-width: 50px;
  }

  .productSec.list-view-filter .productbxtoprtlst li span img{
    max-width: 11px;
  }

  .productSec.grid-view-filter .productbxtopimg {
    height: 89px;
  }
  .productSec.grid-view-filter .productbxbtm{min-height: 30px;padding: 0;}

  .productbxtoprtlst li {
    font-size: 7px;
    padding: 2.3px 0 2.3px 2px;
  }
  .productbxtoprtlst li span img{
    max-width: 8px;
  }

  .productbxbtm strong {
    font-size: 10px;
    line-height: 12px;
    width: 48px;
  }

  .productbxbtm h3 {
    font-size: 9px;
    line-height: 11px;
  }

  .productSec.list-view-filter .productbxbtm h3 {
    font-size: 15px;
    line-height: 16px;
    width: 100%;
  }

  .productbxbtm::before {
    right: 49px;
  }

  .productSec .row>div {
    padding: 0 6px;
  }

  .productSec .row {
    margin: 0 -6px;
  }

  .productbxtoprtlst {
    align-items: center;
  }
  .closeMnuTwo {
    padding: 0 8px;
  }
  .closeMnuTwo li {
    padding: 0 3px;
  }
  .productSec.list-view-filter .productbxbtm h3{width: 100%;}
  .productSec.grid-view-filter .productcolumn .productbx .productbxtoprtlst{max-width: 56px;}
  .productSec.grid-view-filter .productcolumn .productbx .productbxbtm:before{right: 55px;}
  .productSec.grid-view-filter .productcolumn .productbx .productbxbtm strong {width: 85px;}
   .grayHeart {
    /* top: -2px;
    left: 3px; */
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
}
.dlrTrs{
  top: 6px;
    left: 6px;
}
.list-view-filter  .grayHeart {
 
  width: 18px;
  height: 18px;
}
.productSec.grid-view-filter .productbxtoprtlst li span img {
  max-width: 7px;
}
.productSec.grid-view-filter .productbxtoprtlst li:nth-last-child(1){font-size: 6px;}
.userSmall{
  width: 16px;
  height: 16px;
}

.productSec.grid-view-filter .userSmall{
  /* right: 2px;
  bottom: -2px; */
  width: 14px;
  height: 14px;
  /* padding: 1px; */
  bottom: 4px;
  right: 3.5px;
}




.productSec.grid-view-filter .productcolumn .productbx .productbxbtm h3 {
  font-size: 8PX;
  margin: 0 7px 0 6px;
}

.productSec.grid-view-filter .productcolumn .productbx .productbxbtm strong span {
  top: -8px;
  right: 3px;
 
}

.popbtomtxt,.paypalbtomtxt {
  padding: 0 30px;
}
.popbtomtxt p {
	font-size: 20px;
}
.popbtomtxt p small{
  font-size: 14px;
}

.soldModal { padding: 35px 25px 20px;}
.btnCloseds{top: 12px;right: 12px; border-radius: 12px;width: 15px;}


}
/* @media only screen and (max-width: 380px) {
  .productSec.list-view-filter .productbxtoprtlst li {
    padding: 8px 2px;
  }
} */

@media only screen and (max-width: 380px) {
  .productbxtoprtlst li {
    padding: 2.35px 0 2.35px 2px;
}
  .productSec.list-view-filter .productbxtopimg {
    min-height: 160px;
  }
  .productSec.list-view-filter .productbxtoprtlst li{line-height: 12px;padding: 8.8px 2px;
  }


}



.cardpic{
  width: 100%;
  max-height:150px;
  margin: 10px 0;
  overflow: hidden;
}
.widthadj{
  max-width: 500px;
  margin: 15px auto 40px;
}
.maincardpic{
  padding: 5px;
  border: 5px solid #f8b756;
}
@media only screen and (max-width: 991px) {
.addmainimg{
  margin: 15px auto;
}
}
.widthadj form{
  position: absolute;
  top: 0;
  left: -69px;
  bottom: 0;
}
.cardpic img{
  width: 100%;
  height:100px;
  object-fit: cover;
  max-width: 100%;
}


.spacediv{
  padding: 0 60px;
}
.spacediv figure{
  margin-bottom: 15px;
}


.ogcls {
  display: flex;
  white-space: nowrap;
  align-items: center;
  background: red;
  color: #fff;
  padding: 0 18px;
  font-weight: 800;
  font-size: 17px;
}


.promotbox .productbxtoprtlst li{
  background: rgb(248, 183, 86);
  color: #212121;
  border-bottom: 1px solid #21212191;
}

.promotbox .productbxtoprtlst li span img{
  -webkit-filter: grayscale(100%) brightness(0%);
  filter: grayscale(100%) brightness(0%);
}
.promotbox .productbxbtm strong span  {
  background: #f8b756;
  color: #212121;
}
.promotbox .productbxbtm::before {border-right: 1px solid #21212191;}